<template>
  <div class="row justify-content-center align-items-center" style="--bs-gutter-x:0rem">
    <div class="col-md-4 pe-0">
      <nav class="navbar navbar-dark bg-pvi navbar-expand-lg">
        <div class="container container-fluid">
            <img class="img-navbar" :src="'/img/pvi_offline_600x126.png'" alt="Postventa" style="padding-top: 5px;">
            <a target="_blank" class="vuelve-pvi" :href="linkPvi">
              <img class="img-navbar" :src="'/img/pviblanco_new_corto.png'" alt="Postventa" style="height: 35px;width: 100px;">
            </a>
          </div>
      </nav>
    </div>
  </div>

  <div class="row justify-content-center align-items-center" style="--bs-gutter-x:0rem">
    <div class="col-md-4 pe-0">
      <Slide :closeOnNavigation="true">
        <span class="bg-pvi" style="color: white;font-weight:500;padding: 0.7rem;"> 
          <font-awesome-icon icon="wrench" style="margin-right:15px;margin-top: 5px;"/> 
          Solicitud de Postventa
        </span>
        <router-link to="/oi" class="a-menu"><span class="span-menu">Orden de Inpección</span></router-link>
        <router-link to="/ot" class="a-menu"><span class="span-menu">Orden de Trabajo</span></router-link>
        <a id="ot" @click="cerrarSesion" class="link a-menu" style="border-bottom: 1px solid #c7c7c7;">
          <span class="span-menu">
            <font-awesome-icon icon="arrow-right-from-bracket" style="margin-right:15px;margin-top: 5px;"/>  Cerrar Sesión
          </span>
        </a>
      </Slide>
    </div>
  </div>
  
</template>
  
<script>
  import { Slide } from 'vue3-burger-menu'
  import { useLoginStore } from '@/store/loginStore'
  import { storeToRefs } from "pinia";

  export default {
    name: 'Header',
    components: {
      Slide
    },
    methods: {
      cerrarSesion: function() {
        const loginStore = useLoginStore();
        loginStore.cerrarSesionStore();
      }
    },
    setup() {
      const loginStore = useLoginStore();
      const { configs, linkPvi } = storeToRefs(loginStore);
      return { configs, linkPvi }
  },
  }
</script>
  
<style>
  .vuelve-pvi{
    border: 1px solid #dbdada;
    padding: 5px;
    border-radius: 5%;
  }

  .span-menu{
    color: #555 !important;
    font-size: 14px !important;
    font-weight:500 !important;
  }

  .a-menu{
    padding: 0.5rem !important;
    border-top: 1px solid #c7c7c7;
    border-right: 1px solid #c7c7c7;
  }

  .bm-menu{
    padding-top: initial !important;
  }

  .bm-item-list{
    margin-left: initial !important;
  }
  .bm-burger-bars{
    background-color: #fdfdfdad !important;
    border-radius: 10px !important;
  }

  .bm-burger-button{
    left: initial !important;
    top: 14px !important;
    margin-left: 15px;
  }

  .bm-menu{
    left: initial !important;
    background-color: #f5f5f5 !important;
  }

  .bg-pvi{
    background-color: #29abd4
  }

  .img-navbar{
    width: 180px;
    padding-left: 2%;
  }

  .link {
    color: #818181; /* Cambia "gray" por el tono de gris que desees */
    text-decoration: none; /* Elimina la decoración de texto predeterminada */
    cursor:pointer;
  }

  .link:hover {
    color: #818181; /* Cambia "darkgray" por el tono de gris que deseas cuando el enlace está en estado de "hover" */
    text-decoration: underline; /* Elimina la decoración de texto predeterminada */
    cursor:pointer;
  }
</style>