import idb from '@/api/configDbService'
let DB;

export default {
	async deleteProject(project) {
		let db = await idb.getDb();
		return new Promise(resolve => {
			let trans = db.transaction(['projects'],'readwrite');
			trans.oncomplete = () => {
				resolve();
			};

			let store = trans.objectStore('projects');
			store.delete(project.r_proyecto_id);
		});	
	},
	async getProjects() {
		let db = await idb.getDb();
		return new Promise(resolve => {
			let trans = db.transaction(['projects'],'readonly');
			trans.oncomplete = () => {
				resolve(projects);
			};
			
			let store = trans.objectStore('projects');
			let projects = [];
			
			store.openCursor().onsuccess = e => {
				let cursor = e.target.result;
				if (cursor) {
					projects.push(cursor.value)
					cursor.continue();
				}
			};
		});
	},

	async saveProject(project) {
		let db = await idb.getDb();
		return new Promise(resolve => {
			let trans = db.transaction(['projects'],'readwrite');
			trans.oncomplete = () => {
				resolve();
			};
			let store = trans.objectStore('projects');
			store.put(project);
		});
	}
}