import { defineStore } from 'pinia'
import axios from 'axios'

export const useLoginStore = defineStore('loginStore', {
  state: () => ({
    sesionIniciada: JSON.parse(localStorage.getItem('sesionIniciada')) === null ? 0 : JSON.parse(localStorage.getItem('sesionIniciada')),
    loginError: '',
    configs: [],
    linkPvi: ''
  }),
  actions: {
    iniciaSesion(){
      switch(this.sesionIniciada){
        case 0:
          localStorage.setItem('sesionIniciada', 0);
          break;
        case 1:
          //si hay internet actualizo las configuraciones y el hash
          localStorage.setItem('ultimaConexion', this.getCurrentDate());
          break;
        case 2:
          //si hay internet actualizo las configuraciones, el hash y el user
          this.configs = JSON.parse(localStorage.getItem('configs'));

          var url = process.env.VUE_APP_PVI_URL;
          if (url.includes(this.configs.name)) {
            this.linkPvi = process.env.VUE_APP_PVI_URL;
          } else {
            this.linkPvi = process.env.VUE_APP_PVI_URL + this.configs.name;
          }
          
          localStorage.setItem('ultimaConexion', this.getCurrentDate());
          break;
        default:
          localStorage.setItem('sesionIniciada', 0);
          break;
      }
    },
    async iniciaSesionInterna(info){
      try {
        const url = new URL(location);
        url.searchParams.delete('pvi_offline');

        if(localStorage.getItem('sesionIniciada') != 2){
          this.updateKeyInmobiliaria(info[2]).then(()=>{
              this.login(info[0], info[1]).then(()=>{
                history.replaceState(null, null, url);
                window.location = url.origin;
              });
          });
        }
      }catch (error) {
        //localStorage.setItem('sesionIniciada', 0);
        console.log(error.response.data.message);
      }
    },
    async updateKeyInmobiliaria(key){
      try {
        await axios.get(process.env.VUE_APP_PVI_API_URL+"client?key="+key).then((response) => {
          this.sesionIniciada = 1;
          this.configs = JSON.parse(JSON.stringify(response.data));

          var url = process.env.VUE_APP_PVI_URL;
          if (url.includes(this.configs.name)) {
            this.linkPvi = process.env.VUE_APP_PVI_URL;
          } else {
            this.linkPvi = process.env.VUE_APP_PVI_URL + this.configs.name;
          }

          localStorage.setItem('configs', JSON.stringify(response.data));
          localStorage.setItem('hash', response.data.hash);
          localStorage.setItem('sesionIniciada', 1);
        });
      } catch (error) {
        localStorage.setItem('sesionIniciada', 0);
        alert(error.response.data.message);
      }
    },
    async login(user, pass){
      try {
        let data = {
          cliente_hash: localStorage.getItem('hash'),
          usuario: user, 
          password: pass, 
        };
        let customConfig = {
          headers: {
          'Content-Type': 'multipart/form-data'
          }
        };
        this.loginError = '';
        
        await axios.post(process.env.VUE_APP_PVI_API_URL+"user/login", data, customConfig).then((response) => {
          this.sesionIniciada = 2;
          localStorage.setItem('sesionIniciada', 2);
          localStorage.setItem('user', JSON.stringify(response.data));
          console.log(this.localStorageSpace());
        });
      } catch (error) {
        localStorage.setItem('sesionIniciada', 1);
        this.loginError = error.response.data.message;
        console.log(error.response.data.message);
      }
      
    },
    cerrarSesionStore(){
      var hash = localStorage.getItem('hash');
      var configs = localStorage.getItem('configs');
      localStorage.clear();
      localStorage.setItem('sesionIniciada', 1);
      localStorage.setItem('hash', hash);
      localStorage.setItem('configs', configs);
      location.reload();
    },
    localStorageSpace(){
      var allStrings = '';
      for(var key in window.localStorage){
          if(window.localStorage.hasOwnProperty(key)){
              allStrings += window.localStorage[key];
          }
      }
      return allStrings ? 3 + ((allStrings.length*16)/(8*1024)) + ' KB' : 'Empty (0 KB)';
    },
    getCurrentDate(){
      var today = new Date();
      var hour = today.getHours() < 10 ? "0"+today.getHours() : today.getHours();
      var min = today.getMinutes() < 10 ? "0"+today.getMinutes() : today.getMinutes();
      var month = '';
      var todayComplete = today.getDate()+"/"+(today.getMonth()+1)+"/"+today.getFullYear();
      //var options = {weekday: "long", year: "numeric", month: "long", day: "numeric"};
      var lastConexion =  todayComplete+" a las "+hour+":"+min;
      return lastConexion;
    }
  },
})