<template>
  <div class="row justify-content-center align-items-center">
    <div class="col-md-4 fondo">
      <div style="margin-top: 20%;margin-bottom: 20%">
        <a href="javascript:void(0);">
          <img :src="'/img/pvi_offline_357x126.png'" alt="Postventa" style="width: 70%;">
          </a>
      </div>

      <form class="py-5" @submit.prevent="loginExterno(user, pass)">
        <div class="form-group py-2 px-5">
          <div class="input-field">
            <input type="text" v-model="user" placeholder="Nombre de usuario" required class="form-control inputMobile"> 
          </div>
        </div>
        <div class="form-group py-1 pb-2 px-5">
          <div class="input-field">
            <input type="password" v-model="pass" placeholder="Contraseña" required class="form-control inputMobile">
            <div class="form-text" align="left" v-if="loginError.length > 0" style="color: red;">* {{ loginError }} </div>
          </div>
        </div>

        <div class="form-actions">
          <div class="row pt-4">
            <div style="float: none">
              <button type="submit" id="ingresar" name="ingresar" class="btn btn-block btn-login" style="width: 92%">
                Ingresar
              </button>
            </div>
          </div>
        </div>
    </form>
    
      <div class="row pt-5 pb-3 ps-5">
          <div class="pb-2" style="color: #ffffff">
              by <img :src="'/img/logo_planok.png'" alt="PlanOK" style="width: 150px;margin: 2%">
          </div>
          <div class="p-5" style="text-align: left;">
              <div class="row pb-3">
                  <div>
                    <font-awesome-icon icon="phone"/>
                    <span class="ps-2">
                      <a href="tel:+5622439 6900" style="color: #0a0a0a">+56 2 2439 6900</a>
                    </span>
                  </div>
                  <div>
                    <font-awesome-icon icon="envelope"/>
                    <span class="ps-2">
                      <a style="color: #0a0a0a" href="mailto:notificaciones_pvi@planok.com">
                        notificaciones_pvi@planok.com
                      </a>
                    </span>
                  </div>
              </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useLoginStore } from '@/store/loginStore'
import { storeToRefs } from "pinia";
import { ref } from "vue";

export default {
  name: 'Login',
  props: {
    msg: String
  },
  setup() {
    const user = ref('');
    const pass = ref('');
    const loginStore = useLoginStore();
    const { loginError } = storeToRefs(loginStore);
    function loginExterno(user, pass) {
      loginStore.login(user, pass);
    }

    return { user, pass, loginError, loginExterno }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
::placeholder {
  color: white;
  opacity: 1;
}
.inputMobile{
  width: 100%;
    padding: 10px;
    font-size: 15px;
    background-color: #29abd4;
    color: white;
}

.btn-login{
  width: 63%;
  margin-top: 15px;
  margin-bottom: 0;
  padding: 6px 0px;
  border-radius: 15px;
  background-color: #2dc8f6;
  border-color: #2dc8f6;
  color: #fff;
}
.fondo{
  background-color: #29abd4
}
</style>
