import { createApp } from 'vue'
import App from './App.vue'
import { library } from "@fortawesome/fontawesome-svg-core";

/* import specific icons */
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faWifi } from '@fortawesome/free-solid-svg-icons';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { faWrench, faArrowsRotate, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { faBullhorn, faEye, faCircleXmark, faFolderOpen, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { faArrowRightFromBracket, faCircleInfo, faCircleExclamation, faFile } from '@fortawesome/free-solid-svg-icons';
import { faXmark, faDownload, faCaretDown, faCircleStop, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { faFilter, faUser, faEllipsisVertical, faClock, faCalendar, faFileText, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VueDatePicker from '@vuepic/vue-datepicker';
import {LoadingPlugin} from 'vue-loading-overlay';


import { VOffline } from 'v-offline';
import { createPinia } from 'pinia'
import router from "./router";
import './registerServiceWorker'

import $ from 'jquery';
window.$ = window.jQuery = $;

import VueUploadComponent from 'vue-upload-component'

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import '@vuepic/vue-datepicker/dist/main.css'
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import 'vue-loading-overlay/dist/css/index.css';

/* import specific icons and add icons to the library */
library.add(
    faFolderOpen,
    faEye,
    faCircleXmark,
    faArrowsRotate,
    faFile,
    faCircleExclamation,
    faTriangleExclamation,
    faCircleCheck,
    faCircleStop,
    faCaretDown,
    faDownload,
    faXmark, 
    faArrowRightFromBracket,
    faBullhorn,
    faWrench,
    faCircleInfo,
    faPhone,
    faEnvelope,
    faWifi,
    faHome,
    faMagnifyingGlass,
    faFilter,
    faEllipsisVertical,
    faUser,
    faClock,
    faCalendar,
    faFileText,
    faCalendarDays,
    faTrash);

createApp(App)
.component("font-awesome-icon", FontAwesomeIcon, 'VOffline', VOffline, 'VueDatePicker', VueDatePicker, 'file-upload', VueUploadComponent)
.use(createPinia())
.use(router)
.use(LoadingPlugin)
.mount('#app');