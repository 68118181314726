import { createWebHistory, createRouter } from "vue-router";
import Ot from "@/components/ordenTrabajo/OtTable.vue";
import Oi from "@/components/ordenInspeccion/OiTable.vue";

const routes = [
  {
    path: "/",
    name: "Oi",
    component: Oi,
  },
  {
    path: "/ot",
    name: "Ot",
    component: Ot,
  },
  {
      path: '/:pathMatch(.*)*',
      redirect: "/"
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;