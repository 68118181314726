import idb from '@/api/configDbService'

export default {
	async deleteOiTable(oiTable) {
		let db = await idb.getDb();
		return new Promise(resolve => {
			let trans = db.transaction(['oiTable'],'readwrite');
			trans.oncomplete = () => {
				resolve();
			};

			let store = trans.objectStore('oiTable');
			store.delete(oiTable.r_orden_inspeccion_id);
		});	
	},
	async deleteAllOiTable(){
		let db = await idb.getDb();
		return new Promise(resolve => {
			let trans = db.transaction(['oiTable'],'readwrite');
			trans.oncomplete = () => {
				resolve();
			};

			let store = trans.objectStore('oiTable');
			store.clear();
		});	
	},
	async getOiTable() {
		let db = await idb.getDb();
		return new Promise(resolve => {
			let trans = db.transaction(['oiTable'],'readonly');
			trans.oncomplete = () => {
				resolve(oiTables);
			};
			
			let store = trans.objectStore('oiTable');
			let oiTables = [];
			
			store.openCursor().onsuccess = e => {
				let cursor = e.target.result;
				if (cursor) {
					oiTables.push(cursor.value)
					cursor.continue();
				}
			};
		});
	},
	async getOiTableByProjectId(projectId){
		let db = await idb.getDb();
		return new Promise(resolve => {
			let trans = db.transaction(['oiTable'],'readonly');
			trans.oncomplete = () => {
				resolve(oiTables);
			};
			
			let store = trans.objectStore('oiTable');
			let oiTables = [];
			
			store.openCursor().onsuccess = e => {
				let cursor = e.target.result;
				if (cursor) {
					if(cursor.value.Proyecto.r_proyecto_id == projectId){
						oiTables.push(cursor.value);
					}
					cursor.continue();
				}
			};
		});
	},
	async getOiTableBuscar(buscar){
		let db = await idb.getDb();
		return new Promise(resolve => {
			let trans = db.transaction(['oiTable'],'readonly');
			trans.oncomplete = () => {
				resolve(oiTables);
			};
			
			let store = trans.objectStore('oiTable');
			let oiTables = [];
			
			store.openCursor().onsuccess = e => {
				let cursor = e.target.result;
				if (cursor) {
					if (cursor.value.Etapa.r_etapa_nombre.toLowerCase().indexOf(buscar.toLowerCase()) != -1 || 
						cursor.value.OrdenInspeccion.r_orden_inspeccion_folio.toLowerCase().indexOf(buscar.toLowerCase()) != -1 || 
						cursor.value.OrdenInspeccion.fecha_visita.toLowerCase().indexOf(buscar.toLowerCase()) != -1 || 
						cursor.value.Propiedad.r_propiedad_direccion.toLowerCase().indexOf(buscar.toLowerCase()) != -1 || 
						cursor.value.Propietario.r_propietario_nombre.toLowerCase().indexOf(buscar.toLowerCase()) != -1 ||
						cursor.value.Proyecto.r_proyecto_nombre.toLowerCase().indexOf(buscar.toLowerCase()) != -1)
					{
						oiTables.push(cursor.value)
					}
					cursor.continue();
				}
			};
		});
	},
	async saveOiTable(oiTable) {
		let db = await idb.getDb();
		return new Promise(resolve => {
			let trans = db.transaction(['oiTable'],'readwrite');
			trans.oncomplete = () => {
				resolve();
			};
			let store = trans.objectStore('oiTable');
			store.put(oiTable);
		});
	},
}