<template>
  <BreadcrumbOt></BreadcrumbOt>
  <div class="row justify-content-center align-items-center" style="--bs-gutter-x:0rem">
    <loading :active="loadingOt" :is-full-page="true" :color="'#29abd4'" :loader="'spinner'"></loading>
    <div v-if="otTableList.length > 0" class="col-md-4 pe-0">
      <div v-for="ot in otTableList" :key="ot.id">
        <div class="bs-callout" :class="[ot.terminada ? 'b-color-status-green' : 'b-color-status-yellow']" aria-multiselectable="true">
          <div class="d-flex justify-content-between">
            <div class="d-flex">
              <h5>
                <a @click="verHistorialPropiedad(ot.Reclamo.r_reclamo_id)" class="link">
                  {{ ot.Propiedad.r_propiedad_direccion }}
                </a>
              </h5>
            </div>
            <div class="d-flex p-1">
              <span v-if="ot.actualizada == 2" class="badge" style="background-color:rgb(169 169 169); color:black; height: 60%; margin-right:5%">
                <font-awesome-icon icon="circle-check"/> Cerrada sin sincronizar
              </span>
              <span v-if="ot.actualizada == 4 || ot.actualizada == 5" class="badge" style="background-color:rgb(169 169 169); color:black; height: 60%; margin-right:5%">
                <font-awesome-icon icon="circle-check"/> Cierre a distancia sin sincronizar
              </span>

              <div class="btn-group" role="group">
                <button type="button" class="btn btn-outline-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" style="border:none;">
                  <font-awesome-icon icon="ellipsis-vertical"/>
                </button>
                <ul class="dropdown-menu">
                  <li>
                    <a class="dropdown-item link" @click="reprogramarOt(ot.Reclamo.r_reclamo_id)">
                      <font-awesome-icon icon="calendar-days" size="1x"/> Reprogramar OT
                    </a>
                  </li>
                </ul>
              </div>

              <!--<div class="dropdown">
                <button class="btn dropdown-toggle" type="button" id="accionesMenu" data-bs-toggle="dropdown" aria-expanded="false">
                  <font-awesome-icon icon="ellipsis-vertical"/>
                </button>
                <ul class="dropdown-menu" aria-labelledby="accionesMenu">
                  <li><a class="dropdown-item" href="#">Historial</a></li>
                  <li><a class="dropdown-item" href="#">Mensajeria</a></li>
                </ul>
              </div>-->
            </div>
          </div>
          <div class="d-flex flex-column align-items-start">
            <div>
              <label class="labelGris">{{ ot.Proyecto.r_proyecto_nombre }} / {{ ot.Etapa.r_etapa_nombre }}</label>
            </div>
            <div class="d-flex">
              <font-awesome-icon class="labelGris" icon="clock"/>
              <label class="labelGris ps-3">F. Emisión: {{ fechaFormateada(ot.OrdenTrabajo.fecha_emision) }}</label>
            </div>
            <div class="d-flex">
              <font-awesome-icon class="labelGris" icon="clock"/>
              <label class="labelGris ps-3">F. Inicio: {{ fechaFormateada(ot.OrdenTrabajo.fecha_inicio) }}</label>
            </div>
            <div class="d-flex">
              <font-awesome-icon class="labelGris" icon="calendar"/>
              <label class="labelGris ps-3">Dias Estimados: {{ ot.OrdenTrabajo.r_orden_trabajo_duracion }}</label>
            </div>
            <div class="d-flex">
              <font-awesome-icon class="labelGris" icon="user"/>
              <label class="labelGris ps-3">
                <a @click="verDatosPropietario(ot.Propietario)" class="link">
                  {{ ot.Propietario.r_propietario_nombre }}
                </a>
              </label>
            </div>
            <div>
              <font-awesome-icon class="labelGris" icon="file-text"/>
              <label class="labelGris ps-3">Folio: {{ot.OrdenTrabajo.r_orden_trabajo_folio}} </label>
            </div>
          </div>

          <!--REQUERIMIENTOS-->
          <div class="accordion" v-bind = "{id:'accordion_'+ot.r_orden_trabajo_id}">
            <h2 class="accordion-header" v-bind = "{id:'headingOne_'+ot.r_orden_trabajo_id}">
              <button type="button" class="btn btn-secondary" data-bs-toggle="collapse" v-bind = "{'data-bs-target':'#colapse_rec_'+ot.r_orden_trabajo_id}"  aria-expanded="true" aria-controls="collapseOne">
                <span class="badge bg-secondary">{{ ot.count_reqs }}</span> Requerimientos
              </button>
            </h2>
            <div v-bind = "{id:'colapse_rec_'+ot.r_orden_trabajo_id, 'aria-labelledby':'headingOne_'+ot.r_orden_trabajo_id, 'data-bs-parent':'#accordion_'+ot.r_orden_trabajo_id}" class="accordion-collapse collapse">
              <div class="accordion-body px-0">
                    
                <!-- ACCIONES MASIVA -->
                <div class="d-flex justify-content-between pb-4">
                  <div class="d-flex">
                    <div class="form-check pt-2">
                      <input class="form-check-input" type="checkbox" value="" @click="checkAllReqs($event, ot.Requerimientos)" id="flexCheckDefault">
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="dropdown">
                      <button class="btn border dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                          Acciones Masivas <font-awesome-icon icon="caret-down"/>
                      </button>
                      <ul class="dropdown-menu" aria-labelledby="accionesMenu">
                        <li>
                          <a class="dropdown-item" v-bind:class = "(ot.terminada) ? 'disabled' : ''" @click="terminarReqsMasivo(ot.Reclamo.r_reclamo_id)">
                            <font-awesome-icon icon="circle-stop"/> Terminar reqs.
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <!-- FIN ACCIONES MASIVA -->

                <div class="container mb-2" style="background-color: #EFEFEF;" v-for="req in ot.Requerimientos" v-bind:key="req.Requerimiento.r_requerimiento_id">
                  <div class="d-flex justify-content-between">
                    <div class="d-flex">
                      <div class="form-check pt-2">
                        <input class="form-check-input" type="checkbox" v-model="req.checked">
                        <label class="form-check-label" for="flexCheckDefault">
                          {{ req.Requerimiento.r_requerimiento_folio }}
                        </label>
                      </div>
                    </div>
                    <div class="d-flex">
                      <div class="py-2">
                        <span v-if="req.Requerimiento.terminado" class="badge" style="background-color:#CFFFD1;color: green;">
                          <font-awesome-icon icon="circle-check"/> Terminada
                        </span>
                        <span v-if="!req.Requerimiento.terminado" class="badge" style="background-color:#FFF8E6;color:#F7BB10;">
                          <font-awesome-icon icon="clock"/> Generada
                        </span>
                      </div>
                    </div>
                    <div class="d-flex">
                      <div class="dropdown">
                        <button class="btn dropdown-toggle" type="button" id="accionesMenu" data-bs-toggle="dropdown" aria-expanded="false">
                          <font-awesome-icon icon="ellipsis-vertical"/>
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="accionesMenu">
                          <li>
                            <a class="dropdown-item" v-bind:class = "(ot.terminada || req.Requerimiento.terminado) ? 'disabled' : ''" @click="terminarReq(req, ot)">
                              <font-awesome-icon icon="circle-stop"/> Terminar req.
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="d-flex justify-content-between">
                    <div class="d-flex">
                      <label class="labelGris" align="left">
                        {{ req.Recinto.r_recinto_nombre }} / {{ req.Lugar.r_lugar_nombre }} / {{ req.Item.r_item_nombre }} / {{ req.Problema.r_problema_nombre }}
                      </label>
                    </div>
                    <div class="d-flex">
                      <div v-if="req.Requerimiento.r_requerimiento_urgente == '1'">
                        <Popper content="Requerimiento urgente" closeDelay="5">
                          <font-awesome-icon size="sm" :style="{ color: '#D83A2A' }" icon="circle-exclamation"/>
                        </Popper>
                      </div>
                      <div v-if="req.Requerimiento.r_requerimiento_status_garantia == 'VENCIDA'">
                        <Popper content="Ítem fuera de garantía" closeDelay="5">
                          <font-awesome-icon size="sm" :style="{ color: '#EDCA37' }" icon="triangle-exclamation"/>
                        </Popper>
                      </div>
                      <div v-if="req.Item.r_criticidad_id == '2'">
                        <Popper content="Ítem crítico" closeDelay="5">
                          <font-awesome-icon size="sm" :style="{ color: '#D83A2A' }" icon="triangle-exclamation"/>
                        </Popper>
                      </div>
                    </div>
                  </div>

                  <div class="row" align="left">
                    <div class="col-12 py-3">
                      <span>Instrucción: </span>
                      <span class="labelGris" v-if="req.Requerimiento.r_requerimiento_detalle_problema !== null && req.Requerimiento.r_requerimiento_detalle_problema.length !== 0">{{ req.Requerimiento.r_requerimiento_detalle_problema }}</span>
                      <span class="labelGris" v-else>Sin Comentarios...</span>
                    </div>
                    <div class="col-12 py-3">
                      <span>F. Solicitud: </span>
                      <span class="labelGris">{{ fechaFormateada(req.Reclamo.r_reclamo_fecha_solicitud) }}</span>
                    </div>
                  </div>
                </div>

                <div class="row p-3 justify-content-end" v-if="ot.terminada">
                  <button type="button" class="btn" :disabled="ot.cierreDistanciaPendiente || ot.actualizada == 2 || (ot.actualizada == 4 || ot.actualizada == 5)" @click="conformidadOt(ot.Reclamo.r_reclamo_id)" style="background-color: #11A2CF;color: white; width: 55%;">
                    Conformidad OT  <font-awesome-icon size="xs" icon="file"/>
                  </button>
                  <div class="form-text" align="right" v-if="(ot.cierreDistanciaPendiente && ot.terminada) || (ot.actualizada == 4 || ot.actualizada == 5)">
                    * Opción no disponible cierre a distancia en curso
                  </div>
                </div>
                  
              </div>
            </div>
          </div>
          <!-- FIN REQUERIMIENTOS-->
        </div>
      </div>
    </div>
    <div v-else class="col-md-4 pe-0 mt-2">
      <div class="card">
        <div class="card-body p-5" align="left">
          No se encuentran solicitudes disponibles.
          <br><br>
          Nota: En Postventa Offline se traen las OIs y OTs agendadas 15 días corridos hacia adelante o 15 días corridos hacia atrás del día actual, para no sobrepasar la memoria del dispositivo móvil.
          <br><br>
          Ejemplo: Si hoy es 15 de diciembre, se traerán las OIs y OTs agendadas desde 1 al 30 de diciembre.
        </div>
      </div>
    </div>
  </div>
  <ModalDatosPropietario v-show="modalDatosPropietarioVisible" @close="closeModalDatosPropietario"></ModalDatosPropietario>
  <ModalHistorialPropiedad v-show="modalHistorialPropiedadVisible" @close="closeModalHistorialPropiedad"></ModalHistorialPropiedad>
  <ModalReprogramarOT v-show="modalReprogramarOTVisible" @close="closeModalReprogramarOT"></ModalReprogramarOT>
  <ModalEnviarEmail v-show="modalEnviarEmailVisible" @close="closeModalEnviarEmail"></ModalEnviarEmail>
  <ModalTerminarReq v-show="modalTerminarOtVisible" @close="closeModalTerminarOt"></ModalTerminarReq>
  <ModalConformidadOt v-show="modalConformidadOtVisible" @close="closeModalConformidadOt"></ModalConformidadOt>
  <ModalFirmaDigital v-show="modalFirmaDigitalVisible" @close="closeModalFirmaDigital"></ModalFirmaDigital>
</template>

<script>
  import { useOtTableStore } from '@/store/otTableStore';
  import { storeToRefs } from "pinia";
  import ModalDatosPropietario from './ModalDatosPropietario.vue';
  import ModalHistorialPropiedad from './ModalHistorialPropiedad.vue';
  import ModalReprogramarOT from './ModalReprogramarOT.vue';
  import ModalEnviarEmail from './ModalEnviarEmail.vue';
  import ModalTerminarReq from './ModalTerminarReq.vue';
  import ModalConformidadOt from './ModalConformidadOt.vue';
  import ModalFirmaDigital from './ModalFirmaDigital.vue';
  import Loading from 'vue3-loading-overlay';
  import BreadcrumbOt from './BreadcrumbOt.vue';
  import Popper from "vue3-popper";

  export default {
    components: {
      Loading,
      BreadcrumbOt,
      ModalDatosPropietario,
      ModalHistorialPropiedad,
      ModalReprogramarOT,
      ModalEnviarEmail, 
      ModalTerminarReq,
      ModalConformidadOt,
      ModalFirmaDigital,
      Popper
    },
    data() {
      return {
        modalDatosPropietarioVisible: false,
        modalHistorialPropiedadVisible: false,
        modalReprogramarOTVisible: false,
        modalEnviarEmailVisible: false,
        modalTerminarOtVisible: false,
        modalConformidadOtVisible: false,
        modalFirmaDigitalVisible: false,
        loadRender: null,
        loader: null
      }
    },
    methods:{
      esFechaValida(fecha) {
        const fechaObj = new Date(fecha);
        return !isNaN(fechaObj) && fechaObj.toString() !== 'Invalid Date';
      },
      fechaFormateada(fecha) {
        if (this.esFechaValida(fecha)) {
          const fechaObj = new Date(fecha);
          const dia = String(fechaObj.getUTCDate()).padStart(2, '0'); // , Para que no afecte la zona horaria, se agrega padStart() para formatear con dos dígitos
          const mes = String(fechaObj.getUTCMonth() + 1).padStart(2, '0'); // Los meses en JavaScript son base 0, se agrega padStart() para formatear con dos dígitos
          const anio = fechaObj.getFullYear();
          return `${dia}-${mes}-${anio}`;
        } else {
          return '';
        }
      },
      showModalDatosPropietario() {
        this.modalDatosPropietarioVisible = true;
      },
      closeModalDatosPropietario() {
        this.modalDatosPropietarioVisible = false;
      },
      showModalHistorialPropiedad() {
        this.modalHistorialPropiedadVisible = true;
      },
      closeModalHistorialPropiedad() {
        this.modalHistorialPropiedadVisible = false;
      },
      showModalReprogramarOT() {
        this.modalReprogramarOTVisible = true;
      },
      closeModalReprogramarOT() {
        this.modalReprogramarOTVisible = false;
      },
      showModalEnviarEmail() {
        this.modalEnviarEmailVisible = true;
      },
      closeModalEnviarEmail() {
        this.modalEnviarEmailVisible = false;
      },
      showModalFirmaDigital() {
        this.modalFirmaDigitalVisible = true;
      },
      closeModalFirmaDigital() {
        this.modalFirmaDigitalVisible = false;
      },
      showModalTerminarOt() {
        this.modalTerminarOtVisible = true;
      },
      closeModalTerminarOt() {
        this.modalTerminarOtVisible = false;
      },
      showModalConformidadOt() {
        this.modalConformidadOtVisible = true;
      },
      closeModalConformidadOt() {
        this.modalConformidadOtVisible = false;
      },
      checkAllReqs:function(event, reqs){
        this.otTableList.forEach(function(ot) {
          ot.Requerimientos.forEach(function(req){
            reqs.forEach(function(_req) {
              if(_req.Requerimiento.r_requerimiento_id == req.Requerimiento.r_requerimiento_id){
                if(event.srcElement.checked){
                  req.checked = true;
                }else{
                  req.checked = false;
                }
              }
            });
          });
        });
      },
      terminarReqsMasivo(idReclamo){
        let ot = this.otTableList.filter(function (ot) { return ot.Reclamo.r_reclamo_id == idReclamo });
        let reqChecks = ot[0].Requerimientos.filter(function (req){ return req.checked });
        this.otTerminar = ot[0];
        if(reqChecks.length == 0){
          alert('Debe seleccionar al menos un requerimiento');
          return false;
        }
        let terminados = reqChecks.filter(function(terminados){ return terminados.Requerimiento.terminado });
        if(terminados.length > 0){
          alert('Almenos 1 requerimiento ya esta terminado.');
          return false;
        }
        
        this.reqsTerminar = reqChecks;
        this.showModalTerminarOt();
      },
      terminarReq(req, ot){
        this.otTerminar = ot;
        this.reqsTerminar = [];
        this.reqsTerminar.push(JSON.parse(JSON.stringify(req)));
        this.showModalTerminarOt();
      },
      conformidadOt(idReclamo){
        let ot = this.otTableList.filter(function (ot) { return ot.Reclamo.r_reclamo_id == idReclamo });
        this.otConformidad = JSON.parse(JSON.stringify(ot[0]));
        this.reqsConformidad = ot[0].Requerimientos;
        this.quienRecibe = true,
        this.copiaConformidad = false,
        this.showModalConformidadOt();
      },
      verDatosPropietario(prop){
        this.propietario = JSON.parse(JSON.stringify(prop));
        this.showModalDatosPropietario();
      },
      verHistorialPropiedad(idReclamo){
        let ot = this.otTableList.filter(function (ot) { return ot.Reclamo.r_reclamo_id == idReclamo });
        this.reclamosPropiedadList = ot[0].Propiedad.Reclamos;
        this.showModalHistorialPropiedad();
      },
      reprogramarOt(idReclamo){
        let ot = this.otTableList.filter(function (ot) { return ot.Reclamo.r_reclamo_id == idReclamo });
        this.otReprogramar = JSON.parse(JSON.stringify(ot[0]));
        this.reqsConformidad = ot[0].Requerimientos;
        this.showModalReprogramarOT();
      }
    },
    updated: function(){
      if (this.otTableList.length == 0) {
        this.loadRender = this.$loading.show({ color:'#29abd4' });
        this.loader = true;
      } else {
        if (this.loader){
          this.loadRender.hide();
          this.loader = false;
        }
      }

      if (this.loader){
        this.loadRender.hide();
        this.loader = false;
      }
    },
    setup() {
      const tableStore = useOtTableStore();
      tableStore.getDataOtTable();
      tableStore.getDataMotivo();
      tableStore.getDataHoraValida();

      const { otTableList, reclamosPropiedadList, reqsTerminar, reqsConformidad, propietario, otConformidad, 
              otReprogramar, quienRecibe, copiaConformidad, loadingOt, otTerminar } = storeToRefs(tableStore);
      return { otTableList, reclamosPropiedadList, reqsTerminar, reqsConformidad, propietario, otConformidad, otReprogramar, 
                quienRecibe, copiaConformidad, loadingOt, otTerminar }
    }
  }
</script>

<style scoped>
  .completed {
    text-decoration: line-through;
  }
  .bs-callout{
    border: 1px solid transparent;
    font-family: 'Poppins', sans-serif !important;
    margin: 3%;
    padding: 20px;
    border-left-width: 5px;
    border-radius: 3px;
    box-shadow: 0px 3px 15px rgb(113 113 113 / 49%);
  }

  .b-color-status-black{
    background-color: #bbbbbb;
  }
  .b-color-status-grey{
    background-color: #EFEFEF;
  }

  .b-color-status-green{
    border-left-color: #008f39;
  }

  .b-color-status-yellow{
    border-left-color: #F7BB10;
  }

  .labelGris{
    color:#818181 !important
  }
  .dropdown-toggle:after {
    display: none;
  }
  .btn-secondary{
    background: #dbdada;
    width: 100%;
    border: 0px;
    color: #000;
  }
  .badge{
    --bs-badge-border-radius: 1rem;
  }

  :deep(.popper) {
    background: #6a6a6a !important;
    padding: 5px !important;
    border-radius: 10px !important;
    color: #fff !important;
  }

  .link {
    color: #818181; /* Cambia "gray" por el tono de gris que desees */
    text-decoration: none; /* Elimina la decoración de texto predeterminada */
    cursor:pointer;
  }

  .link:hover {
    color: #818181; /* Cambia "darkgray" por el tono de gris que deseas cuando el enlace está en estado de "hover" */
    text-decoration: underline; /* Elimina la decoración de texto predeterminada */
    cursor:pointer;
  }
</style>