<template>
    <transition name="modal-fade">
        <div class="modal-mask">
            <div class="modal-wrapper pt-2">
                <div class="modal-container modal-dialog-scrollable">

                    <div class="modal-header">
                        <div class="d-flex justify-content-between">
                            <label><strong>¿Desea enviar un respaldo por email?</strong></label>
                            <label class="pb-2" @click="$emit('close')"><strong>x</strong></label>
                        </div>
                    </div>

                    <hr style="margin:0px">

                    <div class="modal-body">
                        <div class="container">
                            <div class="row">
                                <div class="col-12 mb-3" align="left">
                                    <label class="col-12">
                                        <small>Si no tiene internet, el email se enviará automáticamente cuando usted cuente nuevamente con conexión. Si no desea enviar un respaldo por email, no seleccione ninguna opción.</small>
                                    </label>
                                </div>

                                <div class="col-12 mb-3" align="left">
                                    <div class="form-check" id="divProp" v-if="otReprogramar.Propietario && otReprogramar.Propietario.r_propietario_email">
                                        <label class="col-12 mb-3">
                                            <input type="checkbox" id="cbxPropietario" name="cbxPropietario" ref="cbxPropietario" class="form-check-input">
                                            <span>Enviar email al propietario</span>
                                            <label>({{ otReprogramar.Propietario.r_propietario_email }})</label>
                                        </label>
                                    </div>
                                    
                                    <div class="form-check" id="divArren" v-if="otReprogramar.Arrendatario && otReprogramar.Arrendatario.r_arrendatario_email">
                                        <label class="col-12 mb-3">
                                            <input type="checkbox" id="cbxArrendatario" name="cbxArrendatario" ref="cbxArrendatario" class="form-check-input">
                                            <span>Enviar email al arrendatario</span>
                                            <label>({{ otReprogramar.Arrendatario.r_arrendatario_email }})</label>
                                        </label>
                                    </div>
                                    
                                    <div class="form-check" id="divCbxMailCopia">
                                        <label class="col-12">
                                            <input type="checkbox" id="cbxMailCopia" name="cbxMailCopia" ref="cbxMailCopia" class="form-check-input" @click="mostrarDivEmailCC();">
                                            Enviar email con copia
                                        </label>

                                        <div class="col-12 mb-3" align="left" id="divTxtEmailCopia" ref="divTxtEmailCopia" v-if="!isHiddenTxtEmailCopia">
                                            <label class="col-12">Email CC</label>
                                            <label class="col-12">
                                                <input type="text" v-model="emailList" @input="validarEmailList" class="form-control" placeholder="nombre@gmail.com" id="txtEmailCopia" name="txtEmailCopia" ref="txtEmailCopia" style="font-weight:normal;">
                                            </label>
                                            <label class="col-12">
                                                <div v-for="(email, index) in invalidEmails" :key="index">
                                                    <small class="fw-bold">
                                                        <label>El email</label> <label class="text-danger">{{ email }}</label> <label>no es válido.</label>
                                                    </small>
                                                </div>
                                                <small>Para ingresar varios emails, separelos con coma <b>(,)</b></small>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <div class="justify-content-between align-items-center">
                            <div class="d-flex justify-content-between">
                                <button type="button" class="btn btn-link" @click="cerrar()">
                                    Atrás
                                </button>
                                &nbsp;
                                <button type="button" class="btn" @click="submitEnviarEmail()" style="background-color:rgb(92, 184, 92); color:white;">
                                    Enviar
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import { defineComponent } from "vue";
    import { useOtTableStore } from '@/store/otTableStore'
    import { storeToRefs } from "pinia";

    export default defineComponent({
        name: 'ModalEnviarEmail',
        data() {
            return {
                isHiddenTxtEmailCopia: true,
                emailList: '',
                invalidEmails: []
            };
        },
        computed: {
            dividirEmails() {
                // Divide la cadena de emails en una matriz
                return this.emailList.split(',').map(email => email.trim());
            },
        },
        methods: {
            esEmailValido(email) {
                // Expresión regular para validar el formato del email
                const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9.-]+(\.[a-zA-Z]{2,})+$/;
                return emailRegex.test(email);
            },
            validarEmailList() {
                // Realiza la validación para cada email ingresado y almacena los emails inválidos en la lista
                this.invalidEmails = [];
                if (this.emailList.length > 0) {
                    this.dividirEmails.forEach(email => {
                        if (!this.esEmailValido(email)) {
                            if (email.length > 0) {
                                this.invalidEmails.push(email);
                            } else {
                                this.invalidEmails.push('"vacío"');
                            }
                        }
                    });
                }
            },
            mostrarDivEmailCC(){
                if (this.$refs.cbxMailCopia.checked) {
                    this.isHiddenTxtEmailCopia = false;
                } else {
                    this.isHiddenTxtEmailCopia = true;
                }
            },
            submitEnviarEmail(){
                if (this.invalidEmails.length) {
                    alert('Por favor, ingrese email(s) válido(s).');
                    return;
                }

                const tableStore = useOtTableStore();
                this.otReprogramar.reprogramar.cbxPropietario = false;
                if (typeof this.$refs.cbxPropietario !== 'undefined') {
                    this.otReprogramar.reprogramar.cbxPropietario = this.$refs.cbxPropietario.checked;
                }
                this.otReprogramar.reprogramar.cbxArrendatario = false;
                if (typeof this.$refs.cbxArrendatario !== 'undefined') {
                    this.otReprogramar.reprogramar.cbxArrendatario = this.$refs.cbxArrendatario.checked;
                }
                this.otReprogramar.reprogramar.cbxMailCopia = this.$refs.cbxMailCopia.checked;
                if (this.$refs.cbxMailCopia.checked) {
                    this.otReprogramar.reprogramar.txtEmailCopia = this.$refs.txtEmailCopia.value;
                }
                tableStore.enviarEmailReprogramarOT();

                this.$parent.closeModalEnviarEmail();
                this.$parent.closeModalReprogramarOT();
            },
            cerrar(){
                this.$parent.closeModalEnviarEmail();
            }
        },
        setup() {
            const tableStore = useOtTableStore();
            const { reqsConformidad, otReprogramar, motivosReprogramacion, horasValidas } = storeToRefs(tableStore);
            return { reqsConformidad, otReprogramar, motivosReprogramacion, horasValidas }
        },
    });
</script>

<style scoped>
    .labelGris{
        color:#818181 !important
    }
    .modal-header{
        display: inline !important;
    }
    .modal-mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: table;
        transition: opacity 0.3s ease;
    }

    .modal-wrapper {
        display: table-cell;
    }

    .modal-container {
        width: 365px;
        margin: 0px auto;
        padding: 15px 15px;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
        transition: all 0.3s ease;
        font-family: Helvetica, Arial, sans-serif;
    }

    .modal-header h3 {
        margin-top: 0;
        color: #42b983;
    }

    .modal-body {
        margin-top: 20px;
        height: 530px;
        overflow-y:auto;
    }

    .stepNumber{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        transition: .4s;
        border: solid 6px;
    }

    .steps {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2rem;
        position: relative;
        width: 100%;
    }

    .step-item {
        z-index: 10;
        text-align: center;
    }

    #progress {
        position: absolute;
        width: 37%;
        z-index: 5;
        height: 8px;
        margin-left: 106px;
        margin-bottom: 46px;
        padding-left: 59px;
        background-color: #11A2CF;
    }

    #signature {
        border: 1px solid #cfcfcf;
        border-radius: 5px;
    }
</style>