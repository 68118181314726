const DB_NAME = 'pvi-offline';
const DB_VERSION = 12; //cuando se agrega un nuevo objeto, se sube la versión para que lo cree
let DB;

export default {
	async getDb() {
		return new Promise((resolve, reject) => {
			if(DB) { return resolve(DB); }
			let request = window.indexedDB.open(DB_NAME, DB_VERSION);
			request.onsuccess = e => {
				DB = e.target.result;
				resolve(DB);
			};
			
			request.onupgradeneeded = e => {
				let db = e.target.result;
				if(db.objectStoreNames.contains('otTable') === false){
					db.createObjectStore("otTable", {keyPath:'r_orden_trabajo_id'});
				}

				if(db.objectStoreNames.contains('projects') === false){
					db.createObjectStore("projects", {keyPath:'r_proyecto_id'});
				}

				if(db.objectStoreNames.contains('oiTable') === false){
					db.createObjectStore("oiTable", {keyPath:'r_orden_inspeccion_id'});
				}

				if(db.objectStoreNames.contains('satisfaccion') === false){
					db.createObjectStore("satisfaccion", {keyPath:'r_satisfaccion_id'});
				}

				if(db.objectStoreNames.contains('recinto') === false){
					db.createObjectStore("recinto", {keyPath:'r_recinto_id'});
				}

				if(db.objectStoreNames.contains('lugar') === false){
					db.createObjectStore("lugar", {keyPath:'r_lugar_id'});
				}

				if(db.objectStoreNames.contains('item') === false){
					db.createObjectStore("item", {keyPath:'r_item_id'});
				}

				if(db.objectStoreNames.contains('problema') === false){
					db.createObjectStore("problema", {keyPath:'r_problema_id'});
				}

				if(db.objectStoreNames.contains('motivo_reprogramacion') === false){
					db.createObjectStore("motivo_reprogramacion", {keyPath:'r_motivo_id'});
				}

				if(db.objectStoreNames.contains('horas_validas') === false){
					db.createObjectStore("horas_validas", {keyPath:'r_hora_id'});
				}
			};

			request.onerror = e => {
				console.log('Error abriendo db', e);
				reject('Error');
			};
		});
	}
}