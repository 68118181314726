<template>
  <transition name="modal-fade">
    <div class="modal-mask">
      <div class="modal-wrapper pt-2">
        <div class="modal-container">

          <div class="modal-header">
            <div class="d-flex justify-content-between">
              <label><strong>Terminar Requerimiento(s)</strong></label>
              <label class="btn btn-sm btn-circle absolute right-2 top-2" @click="$emit('close')"><strong>x</strong></label>
            </div>
            <div class="d-flex justify-content-between">
              <label class="labelGris"><small>Email Término OT</small></label>
            </div>
          </div>  

          <hr style="margin:0px">

          <div class="modal-body">
            <div class="row pb-2 justify-content-start" align="left">
              <label>Requerimiento(s): </label>
              <div v-for="req in reqsTerminar" :key="req.Requerimiento.r_requerimiento_id">
                <label>{{ req.Requerimiento.r_requerimiento_folio }}</label>
              </div>
            </div>
            <div class="mb-3" align="left">
              <label class="col-form-label">Fecha Término</label>
              <VueDatePicker v-model="date" :format="format"></VueDatePicker>
            </div>
          </div>

          <div class="modal-footer">
            <div class="row p-3 justify-content-end">
              <button type="button" class="btn" @click="terminaReqs()" style="background-color: #5CB85C;color: white;">
                Terminar Requerimiento  <font-awesome-icon icon="circle-stop"/>
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import { useOtTableStore } from '@/store/otTableStore'
  import { storeToRefs } from "pinia";
  import VueDatePicker from '@vuepic/vue-datepicker';
  import '@vuepic/vue-datepicker/dist/main.css'

  export default {
    name: 'ModalTerminarRq',
    components: { VueDatePicker },
    methods:{
      terminaReqs: function(){
        var fechaInicioOt = new Date(this.otTerminar.OrdenTrabajo.fecha_inicio);
        if (fechaInicioOt.getTime() >= this.date.getTime()) { // comparación de fechas
          const formatFechaInicio = this.format(fechaInicioOt);
          alert('Fecha de termino no puede ser antes de fecha Inicio OT ' + formatFechaInicio);
          return false;
        }

        let tableStore = useOtTableStore();
        tableStore.terminarReq(this.date);
        this.$parent.closeModalTerminarOt();
      }
    },
    setup() {
      const date = new Date();
      const format = (date) => {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
      }

      const tableStore = useOtTableStore();
      const { reqsTerminar, otTerminar } = storeToRefs(tableStore);
      return { reqsTerminar, otTerminar, format }
    },
    data (){
      return { 
        date: new Date()
      }
    }
  };
</script>

<style scoped>
  .labelGris{
    color:#818181 !important
  }
  .modal-header{
    display: inline !important;
  }
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
  }

  .modal-wrapper {
    display: table-cell;
  }

  .modal-container {
    width: 350px;
    margin: 0px auto;
    padding: 15px 15px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
  }

  .modal-header h3 {
    margin-top: 0;
    color: #42b983;
  }

  .modal-body {
    margin: 20px 0;
  }
</style>