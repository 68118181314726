<template>
  <BreadcrumbOi></BreadcrumbOi>
  <div class="row justify-content-center align-items-center" style="--bs-gutter-x:0rem">
    <loading :active="loadingOi" :is-full-page="true" :color="'#29abd4'" :loader="'spinner'"></loading>
    <div v-if="oiTableList.length > 0" class="col-md-4 pe-0">
      <div v-for="oi in oiTableList" :key="oi.id">
        <div class="bs-callout" :class="[oi.OrdenInspeccion.inspeccionada ? 'b-color-status-green' : 'b-color-status-yellow']" aria-multiselectable="true">
          <div class="d-flex justify-content-between">
            <div class="d-flex">
              <h5>
                <a @click="verHistorialPropiedad(oi.Reclamo.r_reclamo_id)" class="link">
                  {{ oi.Propiedad.r_propiedad_direccion }}
                </a>
              </h5>
            </div>
            <div class="d-flex" style="display: inline !important;width: 15px;height: 5px;">
              <div v-if="oi.OrdenInspeccion.urgente">
                <Popper content="Requerimiento urgente" closeDelay="5">
                  <font-awesome-icon size="sm" :style="{ color: '#D83A2A' }" icon="circle-exclamation"/>
                </Popper>
              </div>
              <div v-if="oi.OrdenInspeccion.fuera_garantia">
                <Popper content="Ítem fuera de garantía" closeDelay="5">
                  <font-awesome-icon size="sm" :style="{ color: '#EDCA37' }" icon="triangle-exclamation"/>
                </Popper>
              </div>
              <div v-if="oi.OrdenInspeccion.critico">
                <Popper content="Ítem crítico" closeDelay="5">
                  <font-awesome-icon size="sm" :style="{ color: '#D83A2A' }" icon="triangle-exclamation"/>
                </Popper>
              </div>
            </div>
          </div>
          <div class="d-flex flex-column align-items-start">
            <div>
              <label class="labelGris">{{ oi.Proyecto.r_proyecto_nombre }} / {{ oi.Etapa.r_etapa_nombre }}</label>
            </div>
            <div class="d-flex">
              <font-awesome-icon class="labelGris" icon="clock"/>
              <label class="labelGris ps-3">F. Visita: {{ oi.OrdenInspeccion.fecha_visita }} {{ oi.OrdenInspeccion.r_orden_inspeccion_hora }}</label>
            </div>
            <div class="d-flex">
              <font-awesome-icon class="labelGris" icon="user"/>
              <label class="labelGris ps-3">
                <a @click="verDatosPropietario(oi.Propietario)" class="link">
                  {{ oi.Propietario.r_propietario_nombre }}
                </a>
              </label>
            </div>
            <div class="d-flex">
              <font-awesome-icon class="labelGris" icon="file-text"/>
              <label class="labelGris ps-3">Folio: {{oi.OrdenInspeccion.r_orden_inspeccion_folio}} </label>
            </div>
          </div>

          <!--REQUERIMIENTOS-->
          <div class="accordion" v-bind = "{id:'accordion_'+oi.r_orden_inspeccion_id}">
            <h2 class="accordion-header" v-bind = "{id:'headingOne_'+oi.r_orden_inspeccion_id}">
              <button type="button" class="btn btn-secondary" data-bs-toggle="collapse" v-bind = "{'data-bs-target':'#colapse_rec_'+oi.r_orden_inspeccion_id}"  aria-expanded="true" aria-controls="collapseOne">
                <span class="badge bg-secondary">{{ oi.count_reqs }}</span> Requerimientos
              </button>
            </h2>
            <div v-bind = "{id:'colapse_rec_'+oi.r_orden_inspeccion_id, 'aria-labelledby':'headingOne_'+oi.r_orden_inspeccion_id, 'data-bs-parent':'#accordion_'+oi.r_orden_inspeccion_id}" class="accordion-collapse collapse">
              <div class="accordion-body px-0">
                    
                <!-- ACCIONES MASIVA -->
                <div class="d-flex justify-content-between pb-4">
                  <div class="d-flex">
                    <div class="form-check pt-2">
                        <input class="form-check-input" type="checkbox" value="" @click="checkAllReqs($event, oi.Requerimientos)" id="flexCheckDefault">
                      </div>
                  </div>
                  <div class="d-flex">
                    <div class="dropdown">
                      <button class="btn border dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                          Acciones Masivas <font-awesome-icon icon="caret-down"/>
                      </button>
                      <ul class="dropdown-menu" aria-labelledby="accionesMenu">
                        <li>
                          <a class="dropdown-item" @click="diagnosticarMasivo(oi.r_orden_inspeccion_id)">
                            <font-awesome-icon icon="circle-stop"/> Diagnosticar
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <!-- FIN ACCIONES MASIVA -->

                <div class="container mb-2" :class="[req.Requerimiento.disabled ? 'b-color-status-black' : 'b-color-status-grey']" v-for="req in oi.Requerimientos" v-bind:key="req.Requerimiento.r_requerimiento_id">
                  <div class="d-flex justify-content-between">
                    <div class="d-flex">
                      <div class="form-check pt-2">
                        <input class="form-check-input" type="checkbox" v-if="!req.Requerimiento.disabled" v-model="req.checked">
                        <label class="form-check-label" for="flexCheckDefault">
                          {{ req.Requerimiento.r_requerimiento_folio }}
                        </label>
                      </div>
                    </div>
                    <div class="d-flex">
                      <div class="py-2">
                        <span class="badge" v-if="req.Requerimiento.icono_estado.icon_pwa != ''" :style="{ 'background-color': req.Requerimiento.icono_estado.colorBackgroud, 'color': req.Requerimiento.icono_estado.colorLetra}">
                          <font-awesome-icon :icon="['fas', req.Requerimiento.icono_estado.icon_pwa]"/> {{ req.Requerimiento.icono_estado.descEstado }}
                        </span>
                    </div>
                    </div>
                    <div class="d-flex">
                      <div class="dropdown" v-if="!req.Requerimiento.disabled">
                        <button class="btn dropdown-toggle" type="button" id="accionesMenu" data-bs-toggle="dropdown" aria-expanded="false">
                          <font-awesome-icon icon="ellipsis-vertical"/>
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="accionesMenu">
                          <li>
                            <a class="dropdown-item" @click="diagnosticar(req, oi)">
                              <font-awesome-icon icon="circle-stop"/> Diagnosticar
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between">
                    <div class="d-flex" style="padding-left:2px;">
                      <label class="labelGris" align="left">
                        {{ req.Recinto.r_recinto_nombre }} / {{ req.Lugar.r_lugar_nombre }} / {{ req.Item.r_item_nombre }} / {{ req.Problema.r_problema_nombre }}
                      </label>
                    </div>
                    <div class="d-flex">
                      <div v-if="req.Requerimiento.r_requerimiento_urgente == '1'">
                        <Popper content="Requerimiento urgente" closeDelay="5">
                          <font-awesome-icon size="sm" :style="{ color: '#D83A2A' }" icon="circle-exclamation"/>
                        </Popper>
                      </div>
                      <div v-if="req.Requerimiento.r_requerimiento_status_garantia == 'VENCIDA'">
                        <Popper content="Ítem fuera de garantía" closeDelay="5">
                          <font-awesome-icon size="sm" :style="{ color: '#EDCA37' }" icon="triangle-exclamation"/>
                        </Popper>
                      </div>
                      <div v-if="req.Item.r_criticidad_id == '2'">
                        <Popper content="Ítem crítico" closeDelay="5">
                          <font-awesome-icon size="sm" :style="{ color: '#D83A2A' }" icon="triangle-exclamation"/>
                        </Popper>
                      </div>
                    </div>
                  </div>

                  <div class="row" align="left">
                    <div class="col-12 py-3">
                      <span class="labelGris" v-if="req.Requerimiento.r_requerimiento_detalle_problema.length != 0">{{req.Requerimiento.r_requerimiento_detalle_problema}}</span>
                      <span class="labelGris" v-else>Sin Comentarios...</span>
                    </div>
                  </div>
                </div>

                <div class="row p-3 justify-content-end" v-if="configs_oi.tieneComprobanteDigital">
                  <button type="button" class="btn comprobante" :disabled="(oi.cierreDistanciaPendiente || !oi.tiene_reqs_para_conformidad) || oi.actualizada == 2 || oi.actualizada == 4 || !oi.OrdenInspeccion.inspeccionada" @click="comprobanteOi(oi.r_orden_inspeccion_id)">
                    Comprobante OI  <font-awesome-icon size="xs" icon="file"/>
                  </button>
                  <div class="form-text" align="right" v-if="oi.cierreDistanciaPendiente || oi.actualizada == 4">
                    * Opción no disponible cierre a distancia en curso
                  </div>
                </div>
                  
              </div>
            </div>
          </div>
          <!--REQUERIMIENTOS-->

        </div>
      </div>
    </div>
    <div v-else class="col-md-4 pe-0 mt-2">
      <div class="card">
        <div class="card-body p-5" align="left">
          No se encuentran solicitudes disponibles.
          <br><br>
          Nota: En Postventa Offline se traen las OIs y OTs agendadas 15 días corridos hacia adelante o 15 días corridos hacia atrás del día actual, para no sobrepasar la memoria del dispositivo móvil.
          <br><br>
          Ejemplo: Si hoy es 15 de diciembre, se traerán las OIs y OTs agendadas desde 1 al 30 de diciembre.
        </div>
      </div>
    </div>
  </div>
  <ModalDatosPropietario v-show="modalDatosPropietarioVisible" @close="closeModalDatosPropietario"></ModalDatosPropietario>
  <ModalHistorialPropiedad v-show="modalHistorialPropiedadVisible" @close="closeModalHistorialPropiedad"></ModalHistorialPropiedad>
  <ModalDiagnosticar v-show="modalDiagnosticarVisible" @close="closeModalDiagnosticar"></ModalDiagnosticar>
  <ModalDiagnosticarMasivo v-show="modalDiagnosticarMasivoVisible" @close="closeModalDiagnosticarMasivo"></ModalDiagnosticarMasivo>
  <ModalComprobanteOi v-show="modalComprobanteOiVisible" @close="closeModalComprobanteOi"></ModalComprobanteOi>
  <ModalFirmaDigital v-show="modalFirmaDigitalVisible" @close="closeModalFirmaDigital"></ModalFirmaDigital>
  <ModalNoCorresponde v-show="modalNoCorrespondeVisible" @close="closeModalNoCorresponde"></ModalNoCorresponde>
</template>

<script>
  import { useOiTableStore } from '@/store/oiTableStore';
  import { useBaseConocimientoStore } from '@/store/baseConocimientoStore';
  import { storeToRefs } from "pinia";
  import ModalDatosPropietario from './ModalDatosPropietario.vue';
  import ModalHistorialPropiedad from './ModalHistorialPropiedad.vue';
  import ModalDiagnosticar from './ModalDiagnosticar.vue';
  import ModalDiagnosticarMasivo from './ModalDiagnosticarMasivo.vue';
  import ModalComprobanteOi from './ModalComprobanteOi.vue';
  import ModalFirmaDigital from './ModalFirmaDigital.vue';
  import ModalNoCorresponde from './ModalNoCorresponde.vue';
  import Loading from 'vue3-loading-overlay';
  import BreadcrumbOi from './BreadcrumbOi.vue';
  import Popper from "vue3-popper";

  export default {
    components: {
      Loading, 
      BreadcrumbOi, 
      Popper,
      ModalDatosPropietario,
      ModalHistorialPropiedad, 
      ModalDiagnosticar,
      ModalDiagnosticarMasivo,
      ModalComprobanteOi, 
      ModalFirmaDigital, 
      ModalNoCorresponde
    },
    data() {
      return {
        modalDatosPropietarioVisible: false,
        modalHistorialPropiedadVisible: false,
        modalDiagnosticarVisible: false,
        modalDiagnosticarMasivoVisible: false,
        modalComprobanteOiVisible: false,
        modalFirmaDigitalVisible: false,
        modalNoCorrespondeVisible: false,
        loadRender: null,
        loader: null
      }
    },
    methods:{
      checkAllReqs:function(event, reqs){
        this.oiTableList.forEach(function(oi) {
          oi.Requerimientos.forEach(function(req){
            reqs.forEach(function(_req) {
              if(_req.Requerimiento.r_requerimiento_id == req.Requerimiento.r_requerimiento_id){
                if(event.srcElement.checked){
                  req.checked = true;
                }else{
                  req.checked = false;
                }
              }
            });
          });
        });
      },
      showModalDatosPropietario() {
        this.modalDatosPropietarioVisible = true;
      },
      closeModalDatosPropietario() {
        this.modalDatosPropietarioVisible = false;
      },
      showModalHistorialPropiedad() {
        this.modalHistorialPropiedadVisible = true;
      },
      closeModalHistorialPropiedad() {
        this.modalHistorialPropiedadVisible = false;
      },
      showModalNoCorresponde() {
        this.modalNoCorrespondeVisible = true;
      },
      closeModalNoCorresponde() {
        this.modalNoCorrespondeVisible = false;
      },
      showModalFirmaDigital() {
        this.modalFirmaDigitalVisible = true;
      },
      closeModalFirmaDigital() {
        this.modalFirmaDigitalVisible = false;
      },
      showModalDiagnosticar() {
        this.modalDiagnosticarVisible = true;
      },
      closeModalDiagnosticar() {
        this.modalDiagnosticarVisible = false;
      },
      showModalDiagnosticarMasivo() {
        this.modalDiagnosticarMasivoVisible = true;
      },
      closeModalDiagnosticarMasivo() {
        this.modalDiagnosticarMasivoVisible = false;
      },
      showModalComprobanteOi() {
        this.modalComprobanteOiVisible = true;
      },
      closeModalComprobanteOi() {
        this.modalComprobanteOiVisible = false;
      },
      comprobanteOi(oi_id){
        let oi = this.oiTableList.filter(function (oi) { return oi.r_orden_inspeccion_id == oi_id });
        this.oiComprobante = JSON.parse(JSON.stringify(oi[0]));
        this.reqsComprobante = [];
        
        /*oi[0].Requerimientos.forEach(req => {
          if(req.Requerimiento.r_status_req_id != 14 || req.diagnostico != undefined){
            this.reqsComprobante.push(req);
          }
        });*/
        
        this.reqsComprobante = oi[0].Requerimientos;
        this.quienRecibe = true,
        this.copiaComprobante = false,
        this.showModalComprobanteOi();
      },
      diagnosticar(req, oi){
        this.reqDiagnosticar = [];
        this.oiDiagnosticar = [];
        this.reqDiagnosticar = JSON.parse(JSON.stringify(req));
        this.oiDiagnosticar = JSON.parse(JSON.stringify(oi));
        const baseConocimientoStore = useBaseConocimientoStore();
        baseConocimientoStore.filtroItemsProblemas(this.reqDiagnosticar.Requerimiento.combinatoriasPosibles);
        this.showModalDiagnosticar();
      },
      diagnosticarMasivo(oi_id){
        this.oiDiagnosticar =  this.oiTableList.filter(function (oi) { return oi.r_orden_inspeccion_id == oi_id });
        this.reqsDiagnosticarMasivo = this.oiDiagnosticar[0].Requerimientos.filter(function (req){ return req.checked });
        let solVisita = false;
        if(this.reqsDiagnosticarMasivo.length == 0){
          alert('Debe seleccionar al menos un requerimiento');
          return false;
        }

        this.reqsDiagnosticarMasivo.forEach(function(req){
          if(req.Requerimiento.r_status_req_id == 57){
            alert('Uno o mas requerimientos tiene estado Solucionado en visita, no se puede Diagnosticar!');
            solVisita = true;
          }
        });

        if(!solVisita){
          this.showModalDiagnosticarMasivo();
        }
      },
      verDatosPropietario(prop){
        this.propietario = JSON.parse(JSON.stringify(prop));
        this.showModalDatosPropietario();
      },
      verHistorialPropiedad(idReclamo){
        let oi = this.oiTableList.filter(function (oi) { return oi.Reclamo.r_reclamo_id == idReclamo });
        this.reclamosPropiedadList = oi[0].Propiedad.Reclamos;
        this.showModalHistorialPropiedad();
      }
    },
    created: function(){
      const baseConocimientoStore = useBaseConocimientoStore();
      baseConocimientoStore.getDataBaseConocimiento();
    },
    updated: function(){
      if (this.oiTableList.length == 0) {
        this.loadRender = this.$loading.show({ color:'#29abd4' });
        this.loader = true;
      } else {
        if (this.loader) {
          this.loadRender.hide();
          this.loader = false;
        }
      }

      if (this.loader) {
        this.loadRender.hide();
        this.loader = false;
      }
    },
    setup() {
      const tableStore = useOiTableStore();
      tableStore.getDataOiTable();
      const { oiTableList, reclamosPropiedadList, configs_oi, reqDiagnosticar, propietario, oiComprobante, oiDiagnosticar, reqsDiagnosticarMasivo, loadingOi } = storeToRefs(tableStore);
      return {oiTableList, reclamosPropiedadList, configs_oi, reqDiagnosticar, propietario, oiComprobante, oiDiagnosticar, reqsDiagnosticarMasivo, loadingOi }
    },
  }
</script>

<style scoped>
  .comprobante{
    background-color: #11A2CF;
    color: white; 
    width: 55%;
  }
  .comprobante:disabled{
    background-color: #fff !important;
    color: #818181 !important;
    width: 55%;
  }
  .completed {
    text-decoration: line-through;
  }
  .bs-callout{
    border: 1px solid transparent;
    font-family: 'Poppins', sans-serif !important;
    margin: 3%;
    padding: 20px;
    border-left-width: 5px;
    border-radius: 3px;
    box-shadow: 0px 3px 15px rgb(113 113 113 / 49%);
  }

  .b-color-status-black{
    background-color: #bbbbbb;
  }
  .b-color-status-grey{
    background-color: #EFEFEF;
  }

  .b-color-status-green{
    border-left-color: #008f39;
  }

  .b-color-status-yellow{
    border-left-color: #F7BB10;
  }

  .labelGris{
    color:#818181 !important
  }
  .dropdown-toggle:after {
    display: none;
  }
  .btn-secondary{
    background: #dbdada;
    width: 100%;
    border: 0px;
    color: #000;
  }
  .badge{
    --bs-badge-border-radius: 1rem;
  }

  :deep(.popper) {
    background: #6a6a6a !important;
    padding: 5px !important;
    border-radius: 10px !important;
    color: #fff !important;
  }

  .link {
    color: #818181; /* Cambia "gray" por el tono de gris que desees */
    text-decoration: none; /* Elimina la decoración de texto predeterminada */
    cursor:pointer;
  }

  .link:hover {
    color: #818181; /* Cambia "darkgray" por el tono de gris que deseas cuando el enlace está en estado de "hover" */
    text-decoration: underline; /* Elimina la decoración de texto predeterminada */
    cursor:pointer;
  }
</style>