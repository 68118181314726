<template>
  <transition name="modal-fade" @before-enter="resetForm">
    <div class="modal-mask">
      <div class="modal-wrapper pt-2">
        <div class="modal-container modal-dialog-scrollable">

          <div class="modal-header">
            <div class="d-flex justify-content-between">
              <label><strong>Firma Digital OT</strong></label>
              <label class="pb-2" @click="$emit('close')"><strong>x</strong></label>
            </div>
          </div>  

          <hr style="margin:0px">

          <div class="modal-body">
            <div class="accordion" id="accordionExample" align="center">
              <div class="steps">
                <progress id="progress" value=0 max=100 ></progress>
                <div class="step-item">
                  <div class="btn stepNumber" style="border-color: #11A2CF;"><strong>1</strong></div>
                  <div class="step-title">
                    <label class="labelGris">Paso 1</label>
                    <label class="labelGris"><small>Ingreso Conformidad</small></label>
                  </div>
                </div>
                <div class="step-item">
                  <div class="btn stepNumber" style="border-color: #808080;"><strong>2</strong></div>
                  <div class="step-title">
                    <label class="labelGris">Paso 2</label>
                    <label class="labelGris"><small>Firma Conformidad</small></label>
                  </div>
                </div>
              </div>
            </div>
            
            <div v-show="!showSignaturePad">
              <div class="mb-3 pb-1" align="left">
                <h4>Ingreso Conformidad</h4>
              </div>
              <div class="mb-3" align="left">
                <label for="nivelConformidad" class="form-label">Nivel de conformidad</label>
                <select class="form-select" aria-label="Default select example" ref="nivelConformidad" v-model="lstNivelConformidad">
                  <option :value = 0 >Seleccione...</option>
                  <option v-for="sat in satisfaccion" :key="sat.r_satisfaccion_id" :value="sat.r_satisfaccion_id">
                    {{ sat.r_satisfaccion_descripcion}}
                  </option>
                </select>
              </div>
              <div class="mb-3" align="left">
                <label class="form-label">Nombre de quien recibe</label>
                <input type="text" class="form-control" ref="quienRecibeNombre" v-if="!quienRecibe">
                <label class="form-control-plaintext labelGris" v-if="otConformidad.Propietario !== undefined && quienRecibe">
                  {{ otConformidad.Propietario.r_propietario_nombre }}
                </label>
              </div>
              <div class="mb-3" align="left">
                <label>Email de quien recibe</label>
                <input type="text" class="form-control" ref="quienRecibeMail" v-if="!quienRecibe">
                <label class="form-control-plaintext labelGris" v-if="otConformidad.Propietario !== undefined && quienRecibe">
                  {{ otConformidad.Propietario.r_propietario_email }}
                </label>
              </div>
              <div class="mb-3 ps-1" align="left">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" v-model="quienRecibe" id="soyPropietario">
                  <label class="form-check-label" for="soyPropietario">
                    Soy propietario
                  </label>
                </div>
                <div class="form-check pb-1">
                  <input class="form-check-input" type="checkbox" v-model="copiaConformidad" id="quiereCopia">
                  <label class="form-check-label" for="quiereCopia">
                    Quiere copia de conformidad
                  </label>
                </div>
                <div class="mb-3" align="left" v-if="copiaConformidad">
                  <input type="text" class="form-control" ref="copiaConformidadMail">
                  <div class="form-text">Para mas de un email separar con , </div>
                </div>
              </div>
              <div class="mb-3" align="left">
                <label for="txtObservacion" class="form-label">Observaciones asociadas a trabajos</label>
                <textarea class="form-control" id="txtObservacion" name="txtObservacion" ref="txtObservacion" rows="3" v-model="texto" @input="contarCaracteres"></textarea>
                <div class="contador">{{ texto.length }} / {{ maxLength }}</div>
              </div>
            </div>

            <div v-show="showSignaturePad">
              <div class="mb-3 pb-1" align="left">
                <h4>Firma Conformidad</h4>
              </div>

              <div class="mb-3" align="left" v-for="req in reqsConformidad" :key="req.Requerimiento.r_requerimiento_id">
                <div align="left">
                  <label class="labelGris">{{ req.Requerimiento.r_requerimiento_folio }}</label>
                  <label class="labelGris">
                    {{ req.Recinto.r_recinto_nombre+" / "+req.Lugar.r_lugar_nombre+" / "+req.Item.r_item_nombre+
                        " / "+req.Problema.r_problema_nombre+" / "+req.Requerimiento.r_requerimiento_detalle_problema }}
                  </label>
                </div>
              </div>

              <VueSignaturePad saveType="image/jpeg" height="300px" ref="signaturePad" width="96%" id="signature" :options="options"></VueSignaturePad>
              <div class="d-flex justify-content-between align-items-center p-2">
                <label class="labelGris"><small>Firma Aqui</small></label>
                <button type="button" class="btn py-1" @click="limpiarSignaturePad()" style="background-color:#dbdada;">
                  Limpiar
                </button>
              </div>
            </div>

          </div>

          <div class="modal-footer">
            <div class="row p-3 justify-content-end" v-show="!showSignaturePad">
              <button type="button" class="btn" @click="siguiente()" style="background-color:rgb(17, 162, 207);color: white;">
                Siguiente 
              </button>
            </div>

            <div class="justify-content-between align-items-center" v-show="showSignaturePad">
              <div class="d-flex justify-content-between">
                <a @click="volverIngreso()" style="text-decoration:underline; color:black; cursor:pointer;" class="pe-3 mt-2">Atrás</a>
                <button type="button" class="btn" @click="cerrarOt()" style="background-color:rgb(92, 184, 92);color: white;">
                  Cerrar OT
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { defineComponent } from "vue";
import { useOtTableStore } from '@/store/otTableStore'
import { storeToRefs } from "pinia";
import { VueSignaturePad } from 'vue-signature-pad';

  export default defineComponent({
    name: 'ModalFirmaDigital',
    components: { VueSignaturePad },
    data(){
      return {
        texto: '',
        maxLength: 255,
        showSignaturePad: false,
        options: {
          backgroundColor: 'rgb(255, 255, 255)',
          onBegin: () => {
            this.$refs.signaturePad.resizeCanvas();
          }
        },
      }
    },
    methods:{
      contarCaracteres() {
        if (this.texto.length > this.maxLength) {
            this.texto = this.texto.substring(0, this.maxLength);
        }
      },
      siguiente(){
        if(this.$refs.nivelConformidad.value == 0){
          alert('Debe seleccionar Nivel de conformidad');
          return false;
        }

        if(!this.quienRecibe){
          if(this.$refs.quienRecibeNombre.value == ''){
            alert('Debe agregar nombre de quien recibe');
            return false;
          }
          if(this.$refs.quienRecibeMail.value == ''){
            alert('Debe agregar mail de quien recibe');
            return false;
          }
        }
        
        if(this.copiaConformidad){
          if(this.$refs.copiaConformidadMail.value == ''){
            alert('Debe agregar copia de conformidad');
            return false;
          }
        }
        
        if(this.showSignaturePad){
          this.showSignaturePad = false;
        }else{
          this.showSignaturePad = true;
        }
      },
      volverIngreso(){
        this.showSignaturePad = false;
      },
      cerrarOt(){
        const { isEmpty, data } = this.$refs.signaturePad.saveSignature('image/jpeg', 0.5);
        if(isEmpty){
          alert('Debe agregar una firma... ');
          return false;
        }
        
        this.otConformidad.Conformidad = {};
        this.otConformidad.Conformidad.recibePropietario = this.quienRecibe;
        this.otConformidad.Conformidad.nombreQuienRecibe = ((this.$refs.quienRecibeNombre != undefined) ? this.$refs.quienRecibeNombre.value.length : '');
        this.otConformidad.Conformidad.mailQuienRecibe = ((this.$refs.quienRecibeMail != undefined) ? this.$refs.quienRecibeMail.value.length : '');
        this.otConformidad.Conformidad.nivelConformidad = ((this.$refs.nivelConformidad != undefined) ? this.$refs.nivelConformidad.value.length : '');
        this.otConformidad.Conformidad.observacionConformidad = ((this.$refs.txtObservacion != undefined) ? this.$refs.txtObservacion.value : '');
        this.otConformidad.Conformidad.copiaConformidad = this.copiaConformidad;
        this.otConformidad.Conformidad.copiaConformidadMail = ((this.$refs.copiaConformidadMail != undefined) ? this.$refs.copiaConformidadMail.value.length : '');
        this.otConformidad.Conformidad.firma = data;

        const tableStore = useOtTableStore();
        tableStore.cerrarOt(this.otConformidad);
        this.limpiarSignaturePad();
        this.$parent.closeModalFirmaDigital();
      },
      limpiarSignaturePad() {
        this.$refs.signaturePad.clearSignature();
      },
      resetForm(){
        this.lstNivelConformidad = 0;
        this.texto = '';
        this.limpiarSignaturePad();
        this.volverIngreso();
      },
    },
    setup() {
      const tableStore = useOtTableStore();
      const { reqsConformidad, otConformidad, satisfaccion, quienRecibe, copiaConformidad } = storeToRefs(tableStore);
      return { reqsConformidad, otConformidad, satisfaccion, quienRecibe, copiaConformidad  }
    }
  });
</script>

<style scoped>
  .labelGris{
    color:#818181 !important
  }
  .modal-header{
    display: inline !important;
  }
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
  }

  .modal-wrapper {
    display: table-cell;
  }

  .modal-container {
    width: 365px;
    margin: 0px auto;
    padding: 15px 15px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
  }

  .modal-header h3 {
    margin-top: 0;
    color: #42b983;
  }

  .modal-body {
    margin-top: 20px;
    height: 530px;
    overflow-y:auto;
  }

  .stepNumber{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    transition: .4s;
    border: solid 6px;
  }

  .steps {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    position: relative;
    width: 100%;
}

.step-item {
    z-index: 10;
    text-align: center;
}

#progress {
    position: absolute;
    width: 37%;
    z-index: 5;
    height: 8px;
    margin-left: 106px;
    margin-bottom: 46px;
    padding-left: 59px;
    background-color: #11A2CF;
}

#signature {
  border: 1px solid #cfcfcf;
  border-radius: 5px;
}
</style>