<template>
    <transition name="modal-fade">
        <div class="modal-mask">
            <div class="modal-wrapper pt-2">
                <div class="modal-container modal-dialog-scrollable">

                    <div class="modal-header">
                        <div class="d-flex justify-content-between">
                            <label><strong>Información del propietario</strong></label>
                            <label class="pb-2" @click="$emit('close')"><strong>x</strong></label>
                        </div>
                    </div>

                    <hr style="margin:0px">

                    <div class="modal-body">
                        <div class="container">
                            <div class="row">
                                <div class="col-12 mb-3" align="left" v-if="propietario.r_propietario_molesto">
                                    <label class="col-12">Tipo de cliente:</label>
                                    <label class="col-12">
                                        <small v-if="propietario.r_propietario_molesto == 1">Neutral</small>
                                        <small v-else-if="propietario.r_propietario_molesto == 2">Sin Conformidad</small>
                                        <small v-else-if="propietario.r_propietario_molesto == 3">Con Demanda</small>
                                        <small v-else-if="propietario.r_propietario_molesto == 4">VIP</small>
                                    </label>
                                </div>

                                <div class="col-12 mb-3" align="left" v-if="propietario.r_propietario_nombre">
                                    <label class="col-12">Nombre completo:</label>
                                    <label class="col-12"><small>{{ propietario.r_propietario_nombre }}</small></label>
                                </div>

                                <div class="col-12 mb-3" align="left" v-if="propietario.r_propietario_email">
                                    <label class="col-12">Email:</label>
                                    <label class="col-12"><small>{{ propietario.r_propietario_email }}</small></label>
                                </div>

                                <div class="col-12 mb-3" align="left" v-if="propietario.r_propietario_fono_of1">
                                    <label class="col-12">Teléfono oficina 1:</label>
                                    <label class="col-12"><small>{{ propietario.r_propietario_fono_of1 }}</small></label>
                                </div>

                                <div class="col-12 mb-3" align="left" v-if="propietario.r_propietario_celular">
                                    <label class="col-12">Celular:</label>
                                    <label class="col-12"><small>{{ propietario.r_propietario_celular }}</small></label>
                                </div>

                                <div class="col-12 mb-3" align="left" v-if="propietario.r_propietario_nacionalidad">
                                    <label class="col-12">Nacionalidad:</label>
                                    <label class="col-12"><small>{{ propietario.r_propietario_nacionalidad }}</small></label>
                                </div>

                                <div class="col-12 mb-3" align="left" v-if="propietario.r_propietario_rut">
                                    <label class="col-12">RUT:</label>
                                    <label class="col-12"><small>{{ propietario.r_propietario_rut }}</small></label>
                                </div>

                                <div class="col-12 mb-3" align="left" v-if="propietario.r_propietario_fono_casa">
                                    <label class="col-12">Teléfono casa:</label>
                                    <label class="col-12"><small>{{ propietario.r_propietario_fono_casa }}</small></label>
                                </div>

                                <div class="col-12 mb-3" align="left" v-if="propietario.r_propietario_fono_of2">
                                    <label class="col-12">Teléfono oficina 2:</label>
                                    <label class="col-12"><small>{{ propietario.r_propietario_fono_of2 }}</small></label>
                                </div>

                                <div class="col-12 mb-3" align="left" v-if="propietario.r_propietario_fax">
                                    <label class="col-12">Fax:</label>
                                    <label class="col-12"><small>{{ propietario.r_propietario_fax }}</small></label>
                                </div>

                                <div class="col-12 mb-3" align="left" v-if="propietario.r_propietario_fecha_nacimiento && esFechaValida(propietario.r_propietario_fecha_nacimiento)">
                                    <label class="col-12">Fecha nacimiento:</label>
                                    <label class="col-12"><small>{{ fechaFormateada(propietario.r_propietario_fecha_nacimiento) }}</small></label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <div class="justify-content-between align-items-center">
                            <div class="d-flex justify-content-between">
                                <button type="button" class="btn" @click="cerrar()" style="background-color:rgb(92, 184, 92); color:white;">
                                    Cerrar 
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import { defineComponent } from "vue";
    import { useOiTableStore } from '@/store/oiTableStore'
    import { storeToRefs } from "pinia";

    export default defineComponent({
        name: 'ModalDatosPropietario',
        methods: {
            esFechaValida(fecha) {
                const fechaObj = new Date(fecha);
                return !isNaN(fechaObj) && fechaObj.toString() !== 'Invalid Date';
            },
            fechaFormateada(fecha) {
                if (this.esFechaValida(fecha)) {
                    const fechaObj = new Date(fecha);
                    const dia = String(fechaObj.getUTCDate()).padStart(2, '0'); // , Para que no afecte la zona horaria, se agrega padStart() para formatear con dos dígitos
                    const mes = String(fechaObj.getUTCMonth() + 1).padStart(2, '0'); // Los meses en JavaScript son base 0, se agrega padStart() para formatear con dos dígitos
                    const anio = fechaObj.getFullYear();
                    return `${dia}-${mes}-${anio}`;
                } else {
                    return '';
                }
            },
            cerrar(){
                this.$parent.closeModalDatosPropietario();
            }
        },
        setup() {
            const tableStore = useOiTableStore();
            const { propietario } = storeToRefs(tableStore);
            return { propietario }
        },
    });
</script>

<style scoped>
    .labelGris{
        color:#818181 !important
    }
    .modal-header{
        display: inline !important;
    }
    .modal-mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: table;
        transition: opacity 0.3s ease;
    }

    .modal-wrapper {
        display: table-cell;
    }

    .modal-container {
        width: 365px;
        margin: 0px auto;
        padding: 15px 15px;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
        transition: all 0.3s ease;
        font-family: Helvetica, Arial, sans-serif;
    }

    .modal-header h3 {
        margin-top: 0;
        color: #42b983;
    }

    .modal-body {
        margin-top: 20px;
        height: 530px;
        overflow-y:auto;
    }

    .stepNumber{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        transition: .4s;
        border: solid 6px;
    }

    .steps {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2rem;
        position: relative;
        width: 100%;
    }

    .step-item {
        z-index: 10;
        text-align: center;
    }

    #progress {
        position: absolute;
        width: 37%;
        z-index: 5;
        height: 8px;
        margin-left: 106px;
        margin-bottom: 46px;
        padding-left: 59px;
        background-color: #11A2CF;
    }

    #signature {
        border: 1px solid #cfcfcf;
        border-radius: 5px;
    }
</style>