<template>
  <transition name="modal-fade">
    <div class="modal-mask">
      <div class="modal-wrapper pt-2">
        <div class="modal-container">

          <div class="modal-header">
            <div class="d-flex justify-content-between">
              <label class="p-3"><strong>Cierre de Requerimiento</strong></label>
              <label class="p-3" @click="$emit('close')">
                <strong style="color:white">x</strong>
              </label>
            </div>
          </div>  

          <hr style="margin:0px">

          <div class="modal-body p-1">
            <div class="form-check ps-2 pb-3" align="left">
              <label class="form-check-label ps-3" for="conFirma" v-if="reqDiagnosticar.length != 0">
                <strong>N° Requerimiento: </strong> {{ reqDiagnosticar.Requerimiento.r_requerimiento_folio }}
              </label>
            </div>
            <div class="form-check ps-5 pb-3" align="left">
              <input class="form-check-input" type="radio" name="tipoCierre" ref="conFirma">
              <label class="form-check-label ps-3" for="conFirma">
                Cerrado con firma
              </label>
            </div>

            <div class="form-check ps-5 pb-3" align="left">
              <input class="form-check-input" type="radio" name="tipoCierre" ref="sinFirma">
              <label class="form-check-label ps-3" for="sinFirma">
                Cerrado sin firma
              </label>
            </div>

            <div class="form-check ps-5 pb-3" align="left">
              <input class="form-check-input" type="radio" name="tipoCierre" ref="noCorresponde">
              <label class="form-check-label ps-3" for="noCorresponde">
                Inspeccionado no corresponde
              </label>
            </div>
          </div>

          <div class="modal-footer px-3">
            <div class="row px-3 pb-3 justify-content-end">
              <button type="button" class="btn" @click="selectCierre()" style="background-color: #5CB85C;color: white;">
                Aceptar
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import { useOiTableStore } from '@/store/oiTableStore'
  import { storeToRefs } from "pinia";

  export default {
    name: 'ModalComprobanteOi',
    methods:{
      selectCierre(){
        if(!this.$refs.conFirma.checked && !this.$refs.sinFirma.checked && !this.$refs.noCorresponde.checked){
          alert('Debe seleccionar alguna opción...');
          return false;
        }else{
          if(this.$refs.conFirma.checked){
            this.reqDiagnosticar.diagnostico.opcionCierre = 3; //Con Firma
          }else if(this.$refs.sinFirma.checked){
            this.reqDiagnosticar.diagnostico.opcionCierre = 1; //Sin Firma
          }else{
            this.reqDiagnosticar.diagnostico.opcionCierre = 2; //No corresponde
          }
          const tableStore = useOiTableStore();
          tableStore.diagnosticaOi();
          this.$parent.closeModalNoCorresponde();
        }
      }
    },
    setup() {
      const tableStore = useOiTableStore();
      const { reqDiagnosticar } = storeToRefs(tableStore);
      return { reqDiagnosticar  }
    }
  };
</script>

<style scoped>
  .labelGris{
    color:#818181 !important
  }
  .modal-header{
    display: block !important;
    background-color: rgb(17, 162, 207);
    color: #fff;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 120%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
  }

  .modal-wrapper {
    display: table-cell;
  }

  .modal-container {
    width: 320px;
    margin: 0px auto;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
  }

  .modal-header h3 {
    margin-top: 0;
    color: #42b983;
  }

  .modal-body {
    margin-top: 20px;
  }
</style>