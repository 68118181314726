import idb from '@/api/configDbService'
export default {
	async deleteRecinto(recinto) {
		let db = await idb.getDb();
		return new Promise(resolve => {
			let trans = db.transaction(['recinto'],'readwrite');
			trans.oncomplete = () => {
				resolve();
			};

			let store = trans.objectStore('recinto');
			store.delete(recinto.r_recinto_id);
		});	
	},
	async deleteLugar(lugar) {
		let db = await idb.getDb();
		return new Promise(resolve => {
			let trans = db.transaction(['lugar'],'readwrite');
			trans.oncomplete = () => {
				resolve();
			};

			let store = trans.objectStore('lugar');
			store.delete(lugar.r_lugar_id);
		});	
	},
	async deleteItem(item) {
		let db = await idb.getDb();
		return new Promise(resolve => {
			let trans = db.transaction(['item'],'readwrite');
			trans.oncomplete = () => {
				resolve();
			};

			let store = trans.objectStore('item');
			store.delete(item.r_item_id);
		});	
	},
	async deleteProblema(problema) {
		let db = await idb.getDb();
		return new Promise(resolve => {
			let trans = db.transaction(['problema'],'readwrite');
			trans.oncomplete = () => {
				resolve();
			};

			let store = trans.objectStore('problema');
			store.delete(problema.r_problema_id);
		});	
	},
	async getRecintos() {
		let db = await idb.getDb();
		return new Promise(resolve => {
			let trans = db.transaction(['recinto'],'readonly');
			trans.oncomplete = () => {
				resolve(recinto);
			};
			
			let store = trans.objectStore('recinto');
			let recinto = [];
			
			store.openCursor().onsuccess = e => {
				let cursor = e.target.result;
				if (cursor) {
					recinto.push(cursor.value)
					cursor.continue();
				}
			};
		});
	},
	async getLugares() {
		let db = await idb.getDb();
		return new Promise(resolve => {
			let trans = db.transaction(['lugar'],'readonly');
			trans.oncomplete = () => {
				resolve(lugar);
			};
			
			let store = trans.objectStore('lugar');
			let lugar = [];
			
			store.openCursor().onsuccess = e => {
				let cursor = e.target.result;
				if (cursor) {
					lugar.push(cursor.value)
					cursor.continue();
				}
			};
		});
	},
	async getItems() {
		let db = await idb.getDb();
		return new Promise(resolve => {
			let trans = db.transaction(['item'],'readonly');
			trans.oncomplete = () => {
				resolve(item);
			};
			
			let store = trans.objectStore('item');
			let item = [];
			
			store.openCursor().onsuccess = e => {
				let cursor = e.target.result;
				if (cursor) {
					item.push(cursor.value)
					cursor.continue();
				}
			};
		});
	},
	async getProblemas() {
		let db = await idb.getDb();
		return new Promise(resolve => {
			let trans = db.transaction(['problema'],'readonly');
			trans.oncomplete = () => {
				resolve(problema);
			};
			
			let store = trans.objectStore('problema');
			let problema = [];
			
			store.openCursor().onsuccess = e => {
				let cursor = e.target.result;
				if (cursor) {
					problema.push(cursor.value)
					cursor.continue();
				}
			};
		});
	},
	async saveRecinto(recinto) {
		let db = await idb.getDb();
		return new Promise(resolve => {
			let trans = db.transaction(['recinto'],'readwrite');
			trans.oncomplete = () => {
				resolve();
			};
			let store = trans.objectStore('recinto');
			store.put(recinto);
		});
	},
	async saveLugar(lugar) {
		let db = await idb.getDb();
		return new Promise(resolve => {
			let trans = db.transaction(['lugar'],'readwrite');
			trans.oncomplete = () => {
				resolve();
			};
			let store = trans.objectStore('lugar');
			store.put(lugar);
		});
	},
	async saveItem(item) {
		let db = await idb.getDb();
		return new Promise(resolve => {
			let trans = db.transaction(['item'],'readwrite');
			trans.oncomplete = () => {
				resolve();
			};
			let store = trans.objectStore('item');
			store.put(item);
		});
	},
	async saveProblema(problema) {
		let db = await idb.getDb();
		return new Promise(resolve => {
			let trans = db.transaction(['problema'],'readwrite');
			trans.oncomplete = () => {
				resolve();
			};
			let store = trans.objectStore('problema');
			store.put(problema);
		});
	},
}