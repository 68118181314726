<template>
  <div v-if="hayCambios || hayCambiosOi" class="row justify-content-center align-items-center" style="--bs-gutter-x:0rem">
    <div class="col-md-4 pe-0 hayCambios" align="left">
        <div class="flex w-full h-full justify-center items-center text-6xl py-2 ps-3"> 
          <strong> Sincroniza para actualizar los datos. </strong> <font-awesome-icon icon="arrows-rotate"/>
        </div>
    </div>
  </div>
</template>

<script>
import { useOtTableStore } from '@/store/otTableStore'
import { useOiTableStore } from '@/store/oiTableStore'
import { storeToRefs } from "pinia";

export default {
  setup() {
    const oTtableStore = useOtTableStore();
    const oItableStore = useOiTableStore();
    const { hayCambios } = storeToRefs(oTtableStore);
    const { hayCambiosOi } = storeToRefs(oItableStore);
    return { hayCambios, hayCambiosOi }
  }
}
</script>
<style scoped>
.hayCambios {
    background-color: #DFF0D8;
}
</style>

