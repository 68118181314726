<template>
    <div v-if="sesionIniciada === 0">
      <Connectivity></Connectivity>
      <Key></Key>
    </div>
    <div v-if="sesionIniciada === 1">
      <Connectivity></Connectivity>
      <Login></Login>
    </div>
    <div v-if="sesionIniciada === 2">
      <Header></Header>
      <Connectivity></Connectivity>
      <HayCambios></HayCambios>
      <router-view></router-view>
    </div>
    <div class="col-md-4" v-if="sesionIniciada === 2">
      <a @click="sincronizar()" class="btn-flotante">
        <font-awesome-icon icon="arrows-rotate" size="2x"/>
      </a>
    </div>
</template>

<script>
  import Header from './components/Header.vue'
  import Login from './components/Login.vue'
  import Key from './components/Key.vue'

  import Connectivity from './components/Connectivity.vue'
  import HayCambios from './components/HayCambios.vue'

  import { useLoginStore } from '@/store/loginStore'
  import { storeToRefs } from "pinia";
  import { useOtTableStore } from '@/store/otTableStore'
  import { useOiTableStore } from '@/store/oiTableStore'

  export default {
    name: 'App',
    components: {
      Login,
      Header,
      Connectivity,
      Key,
      HayCambios
    },
    methods:{
      sincronizar(){
        if(!navigator.onLine){
          alert('Revisa tú conexión a internet');
        }else{
          let loader = this.$loading.show();
          const otTableStore = useOtTableStore();
          otTableStore.sincronizarOt();

          const oiTableStore = useOiTableStore();
          oiTableStore.sincronizarOi().then(function(){
            loader.hide();
          });
        }
      }
    },
  setup() {
    const store = useLoginStore();
    var url = new URL(window.location.href);
    //si viene de PVI tomo las variables y las consulto
    if(url.searchParams.get("pvi_offline") !== null && navigator.onLine && localStorage.getItem('sesionIniciada') != 2){
      store.iniciaSesionInterna(atob(url.searchParams.get("pvi_offline")).split('-'));
    }else if(url.searchParams.get("pvi_offline") !== null){
      url.searchParams.delete('pvi_offline');
      window.location = url.origin;
    }
      // 1 sesion iniciada (no muestra el login)
      store.iniciaSesion();
      const { sesionIniciada } = storeToRefs(store);
      return { sesionIniciada }
    }
  }
</script>

<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
  }

  html, body, div{
    overscroll-behavior: none;
  }

  .btn-flotante {
    color: #ffffff; 
    border-radius: 50%; 
    background-color: #29abd4; 
    padding: 10px 10px; 
    position: fixed;
    bottom: 80px;
    right: 35%;
    transition: all 300ms ease 0ms;
    z-index: 99;
    cursor:pointer;
  }

  .btn-flotante:hover {
    box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
    transform: translateY(-7px);
    color: #c0c2c5;
  }

  @media only screen and (max-width: 600px) {
    .btn-flotante {
      font-size: 14px;
      padding: 10px 10px;
      bottom: 50px;
      right: 30px;
    }
  }
</style>