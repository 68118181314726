<template>
    <transition name="modal-fade">
        <div class="modal-mask">
            <div class="modal-wrapper pt-2">
                <div class="modal-container modal-dialog-scrollable">

                    <div class="modal-header">
                        <div class="d-flex justify-content-between">
                            <label><strong>Información de la propiedad</strong></label>
                            <label class="pb-2" @click="$emit('close')"><strong>x</strong></label>
                        </div>
                    </div>

                    <hr style="margin:0px">

                    <div class="modal-body">
                        <div class="table-responsive">
                            <table class="table table-hover table-bordered table-responsive" style="font-size:0.75em">
                            <thead class="table-light">
                                <tr>
                                    <th>Folio</th>
                                    <th>Fecha solicitud</th>
                                    <th>Fecha de cierre</th>
                                    <th>Estado</th>
                                    <th>Documentos</th>
                                    <th>Cant. insistencias</th>
                                    <th>Cant. requerimientos</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in displayedItems" :key="item.Reclamo.r_reclamo_id">
                                    <td>{{ item.Reclamo.r_reclamo_folio }}</td>
                                    <td>{{ item[0].fecha_solicitud }}</td>
                                    <td>{{ item[0].fecha_cierre_real }}</td>
                                    <td>{{ item.StatusReclamo.r_status_rec_nombre }}</td>
                                    <td>{{ parseInt(item[0].cant_docs_reclamo) + parseInt(item[0].cant_docs_respaldo_ot) + parseInt(item[0].cant_acta_nc) + parseInt(item[0].cant_imagenes) }}</td>
                                    <td>{{ item[0].cant_insistencias }}</td>
                                    <td>{{ item[0].cant_requerimientos }}</td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                        <div class="pagination">
                            <div class="btn-group" role="group" aria-label="Basic example">
                                <button class="btn btn-light" @click="previousPage" :disabled="currentPage === 1">{{ "<" }}</button>
                                <span class="btn btn-secondary disabled">{{ currentPage }}</span>
                                <button class="btn btn-light" @click="nextPage" :disabled="currentPage === totalPages">{{ ">" }}</button>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <div class="justify-content-between align-items-center">
                            <div class="d-flex justify-content-between">
                                <button type="button" class="btn" @click="cerrar()" style="background-color:rgb(92, 184, 92); color:white;">
                                    Cerrar 
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import { defineComponent } from "vue";
    import { useOtTableStore } from '@/store/otTableStore'
    import { storeToRefs } from "pinia";

    export default defineComponent({
        name: 'ModalHistorialPropiedad',
        data() {
            return {
                itemsPerPage: 6,
                currentPage: 1
            };
        },
        computed: {
            displayedItems() {
                const startIndex = (this.currentPage - 1) * this.itemsPerPage;
                const endIndex = startIndex + this.itemsPerPage;
                return this.reclamosPropiedadList.slice(startIndex, endIndex);
            },
            totalPages() {
                return Math.ceil(this.reclamosPropiedadList.length / this.itemsPerPage);
            }
        },
        methods: {
            esFechaValida(fecha) {
                const fechaObj = new Date(fecha);
                return !isNaN(fechaObj) && fechaObj.toString() !== 'Invalid Date';
            },
            fechaFormateada(fecha) {
                if (this.esFechaValida(fecha)) {
                    const fechaObj = new Date(fecha);
                    const dia = String(fechaObj.getUTCDate()).padStart(2, '0'); // , Para que no afecte la zona horaria, se agrega padStart() para formatear con dos dígitos
                    const mes = String(fechaObj.getUTCMonth() + 1).padStart(2, '0'); // Los meses en JavaScript son base 0, se agrega padStart() para formatear con dos dígitos
                    const anio = fechaObj.getFullYear();
                    return `${dia}-${mes}-${anio}`;
                } else {
                    return '';
                }
            },
            cerrar(){
                this.$parent.closeModalHistorialPropiedad();
            },
            previousPage() {
                if (this.currentPage > 1) {
                    this.currentPage--;
                }
            },
            nextPage() {
                if (this.currentPage < this.totalPages) {
                    this.currentPage++;
                }
            }
        },
        setup() {
            const tableStore = useOtTableStore();
            const { reclamosPropiedadList } = storeToRefs(tableStore);
            return { reclamosPropiedadList }
        },
    });
</script>

<style scoped>
    .labelGris{
        color:#818181 !important
    }
    .modal-header{
        display: inline !important;
    }
    .modal-mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: table;
        transition: opacity 0.3s ease;
    }

    .modal-wrapper {
        display: table-cell;
    }

    .modal-container {
        width: 365px;
        margin: 0px auto;
        padding: 15px 15px;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
        transition: all 0.3s ease;
        font-family: Helvetica, Arial, sans-serif;
    }

    .modal-header h3 {
        margin-top: 0;
        color: #42b983;
    }

    .modal-body {
        margin-top: 20px;
        height: 530px;
        overflow-y:auto;
    }

    .stepNumber{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        transition: .4s;
        border: solid 6px;
    }

    .steps {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2rem;
        position: relative;
        width: 100%;
    }

    .step-item {
        z-index: 10;
        text-align: center;
    }

    #progress {
        position: absolute;
        width: 37%;
        z-index: 5;
        height: 8px;
        margin-left: 106px;
        margin-bottom: 46px;
        padding-left: 59px;
        background-color: #11A2CF;
    }

    #signature {
        border: 1px solid #cfcfcf;
        border-radius: 5px;
    }

    /* PAGINADOR */
    .pagination {
        margin-top: 10px;
        display: flex;
        justify-content: center;
    }

    .pagination button {
        margin: 0 5px;
    }
</style>