<template>
  <div class="row justify-content-center align-items-center">
    <div class="col-md-4 fondo">
      <div style="margin-top: 10%;margin-bottom: 10%">
        <a href="javascript:void(0);">
            <img :src="'/img/pvi_offline_357x126.png'" alt="Postventa" style="width: 70%;">
          </a>
      </div>

      <form class="py-3" @submit.prevent="addKey(keyInmobiliaria)">
        <h4 class="pb-4 ps-5" align="left" style="color:#fff">Bienvenido a Offline</h4>
        <label class="pb-3 ps-5" align="left" style="color:#fff">Esta key se ingresa solo al iniciar sesión por primera vez a postventa offline. </label>
        <div class="form-group py-2 px-5">
          <div class="input-field">
            <input type="text" v-model="keyInmobiliaria" placeholder="Key Inmobiliaria" required class="form-control inputMobile">
          </div>
        </div>

        <div class="form-actions">
          <div class="row pt-2">
            <div style="float: none">
              <button type="submit" id="agregar" name="agregar" class="btn btn-block btn-login" style="width: 82%">
                Validar Key
              </button>
            </div>
          </div>
        </div>
    </form>
    
      <div class="pt-4 pb-3 ps-4">
          <div class="col-11 p-4" style="color: #fff;border:solid 2px;border-style:dashed">
              <h5 align="left" class="pb-3">¿No sabes cual es tú key de empresa?</h5>
              <label align="left" class="pb-3">
                Comunicate con su jefatura o comunicate con nuestra mesa de ayuda postventa@planok.com
              </label>
              by <img :src="'/img/logo_planok.png'" alt="PlanOK" style="width: 150px;margin: 2%">
          </div>
          <div class="pt-5" style="text-align: left;">
              <div class="row pb-3">
                  <div>
                    <font-awesome-icon icon="phone"/>
                    <span class="ps-2">
                      <a href="tel:+5622439 6900" style="color: #0a0a0a">+56 2 2439 6900</a>
                    </span>
                  </div>
                  <div>
                    <font-awesome-icon icon="envelope"/>
                    <span class="ps-2">
                      <a style="color: #0a0a0a" href="mailto:notificaciones_pvi@planok.com">
                        notificaciones_pvi@planok.com
                      </a>
                    </span>
                  </div>
              </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useLoginStore } from '@/store/loginStore'
import { ref } from "vue";

export default {
  name: 'Key',
  props: {
    msg: String
  },
  setup() {
    const keyInmobiliaria = ref('');
    const loginStore = useLoginStore();
    function addKey(key) {
      if(key == ''){
        alert('Debe agregar una Key Inmobiliaria')
      }else{
        loginStore.updateKeyInmobiliaria(key);
      }
    }

    return { keyInmobiliaria, addKey }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
::placeholder {
  color: white;
  opacity: 1;
}
.inputMobile{
  width: 100%;
    padding: 10px;
    font-size: 15px;
    background-color: #29abd4;
    color: white;
}

.btn-login{
  width: 63%;
  margin-top: 15px;
  margin-bottom: 0;
  padding: 6px 0px;
  border-radius: 15px;
  background-color: #2dc8f6;
  border-color: #2dc8f6;
  color: #fff;
}
.fondo{
  background-color: #29abd4;
  background-size: cover;
}
</style>
