<template>
    <transition name="modal-fade" @before-enter="resetForm">
        <div class="modal-mask">
            <div class="modal-wrapper pt-2">
                <div class="modal-container modal-dialog-scrollable">

                    <div class="modal-header">
                        <div class="d-flex justify-content-between">
                            <label><strong>Reprogramar OT</strong></label>
                            <label class="pb-2" @click="$emit('close')"><strong>x</strong></label>
                        </div>
                    </div>

                    <hr style="margin:0px">

                    <div class="modal-body">
                        <div class="container">
                            <div class="row">
                                <div class="col-12 mb-3" align="left">
                                    <label class="col-12">Folio / Requerimiento(s):</label>
                                    <label class="col-12">
                                        <ul style="list-style-type:none; padding:0; margin:0;">
                                            <li v-for="req in reqsConformidad" :key="req.Reclamo.r_reclamo_id"><small>{{ req.Requerimiento.r_requerimiento_folio }}</small></li>
                                        </ul>
                                    </label>
                                </div>

                                <div class="col-12 mb-3" align="left">
                                    <label class="col-12">Motivo:</label>
                                    <label class="col-12">
                                        <select id="lstMotivo" name="lstMotivo" ref="lstMotivo" v-model="lstMotivo" class="form-select">
                                            <option v-for="item in motivosReprogramacion" :key="item.r_motivo_id" :value="item.r_motivo_id">
                                                {{ item.r_motivo_desc }}
                                            </option>
                                        </select>
                                    </label>
                                </div>

                                <div class="col-12 mb-3" align="left">
                                    <label class="col-12">Nota:</label>
                                    <label class="col-12">
                                        <textarea id="txtNota" name="txtNota" ref="txtNota" v-model="txtNota" class="form-control" row="3"></textarea>
                                    </label>
                                </div>

                                <div class="col-12 mb-3" style="display:flex; justify-content:center;">
                                    <VueDatePicker v-model="txtDatePicker" inline auto-apply locale="es" :enable-time-picker="false" :min-date="new Date()"></VueDatePicker>
                                </div>

                                <div class="col-6 mb-3" align="left">
                                    <label class="col-12">Hora inicio:</label>
                                    <label class="col-12">
                                        <select id="txtHoraInicio" name="txtHoraInicio" ref="txtHoraInicio" v-model="txtHoraInicio" class="form-select">
                                            <option v-for="item in horasValidas" :key="item.r_hora_id" :value="item.r_hora_desc">
                                                {{ item.r_hora_desc }}
                                            </option>
                                        </select>
                                    </label>
                                </div>

                                <div class="col-6 mb-3" align="left">
                                    <label class="col-12">Hora término:</label>
                                    <label class="col-12">
                                        <select id="txtHoraTermino" name="txtHoraTermino" ref="txtHoraTermino" v-model="txtHoraTermino" class="form-select">
                                            <option v-for="item in horasValidas" :key="item.r_hora_id" :value="item.r_hora_desc">
                                                {{ item.r_hora_desc }}
                                            </option>
                                        </select>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <div class="justify-content-between align-items-center">
                            <div class="d-flex justify-content-between">
                                <button type="button" class="btn" @click="submitReprogramarOT()" style="background-color:rgb(92, 184, 92); color:white;">
                                    Reprogramar 
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import { defineComponent } from "vue";
    import { useOtTableStore } from '@/store/otTableStore'
    import { storeToRefs } from "pinia";
    import VueDatePicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/dist/main.css'

    export default defineComponent({
        name: 'ModalReprogramarOT',
        components: { VueDatePicker  },
        data() {
            return {
                lstMotivo: 1,
                txtNota: '',
                txtDatePicker: '',
                txtHoraInicio: '08:00',
                txtHoraTermino: '08:30'
            };
        },
        methods: {
            esFechaValida(fecha) {
                const fechaObj = new Date(fecha);
                return !isNaN(fechaObj) && fechaObj.toString() !== 'Invalid Date';
            },
            fechaFormateada(fecha) {
                if (this.esFechaValida(fecha)) {
                    const fechaObj = new Date(fecha);
                    const dia = String(fechaObj.getUTCDate()).padStart(2, '0'); // , Para que no afecte la zona horaria, se agrega padStart() para formatear con dos dígitos
                    const mes = String(fechaObj.getUTCMonth() + 1).padStart(2, '0'); // Los meses en JavaScript son base 0, se agrega padStart() para formatear con dos dígitos
                    const anio = fechaObj.getFullYear();
                    return `${dia}-${mes}-${anio}`;
                } else {
                    return '';
                }
            },
            resetForm(){
                this.lstMotivo = 1;
                this.txtNota = '';
                this.txtDatePicker = '';
                this.txtHoraInicio = '08:00';
                this.txtHoraTermino = '08:30';
            },
            submitReprogramarOT(){
                const horaInicio = new Date(`2000-01-01T${this.$refs.txtHoraInicio.value}`);
                const horaFin = new Date(`2000-01-01T${this.$refs.txtHoraTermino.value}`);

                // Validar que no consiste solo en espacios en blanco
                if (/^\s*$/.test(this.$refs.txtNota.value)) {
                    alert('Por favor, ingrese una nota.');
                    return;
                }

                if (!this.txtDatePicker) {
                    alert('Por favor, selecciona una fecha.');
                    return;
                }

                if (horaInicio >= horaFin) {
                    alert('La hora de término debe ser mayor que la hora de inicio.');
                    return;
                }
                
                this.otReprogramar.reprogramar = {};
                this.otReprogramar.reprogramar.motivo = this.$refs.lstMotivo.value;
                this.otReprogramar.reprogramar.nota = this.$refs.txtNota.value;
                this.otReprogramar.reprogramar.fechaReprogramar = this.txtDatePicker;
                this.otReprogramar.reprogramar.horaInicio = this.$refs.txtHoraInicio.value;
                this.otReprogramar.reprogramar.horaTermino = this.$refs.txtHoraTermino.value;
                
                this.otReprogramar.OrdenTrabajo.fecha_emision = this.fechaFormateada(this.txtDatePicker);

                this.$parent.showModalEnviarEmail();
            },
            cerrar(){
                this.$parent.closeModalReprogramarOT();
            }
        },
        setup() {
            const tableStore = useOtTableStore();
            const { reqsConformidad, otReprogramar, motivosReprogramacion, horasValidas } = storeToRefs(tableStore);
            return { reqsConformidad, otReprogramar, motivosReprogramacion, horasValidas }
        },
    });
</script>

<style scoped>
    .labelGris{
        color:#818181 !important
    }
    .modal-header{
        display: inline !important;
    }
    .modal-mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: table;
        transition: opacity 0.3s ease;
    }

    .modal-wrapper {
        display: table-cell;
    }

    .modal-container {
        width: 365px;
        margin: 0px auto;
        padding: 15px 15px;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
        transition: all 0.3s ease;
        font-family: Helvetica, Arial, sans-serif;
    }

    .modal-header h3 {
        margin-top: 0;
        color: #42b983;
    }

    .modal-body {
        margin-top: 20px;
        height: 530px;
        overflow-y:auto;
    }

    .stepNumber{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        transition: .4s;
        border: solid 6px;
    }

    .steps {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2rem;
        position: relative;
        width: 100%;
    }

    .step-item {
        z-index: 10;
        text-align: center;
    }

    #progress {
        position: absolute;
        width: 37%;
        z-index: 5;
        height: 8px;
        margin-left: 106px;
        margin-bottom: 46px;
        padding-left: 59px;
        background-color: #11A2CF;
    }

    #signature {
        border: 1px solid #cfcfcf;
        border-radius: 5px;
    }
</style>