<template>
  <transition name="modal-fade">
    <div class="modal-mask">
      <div class="modal-wrapper pt-2">
        <div class="modal-container modal-dialog-scrollable">

          <div class="modal-header">
            <div class="d-flex justify-content-between">
              <label class="p-3"><strong>Diagnostico Masivo</strong></label>
              <label class="p-3" @click="closeDiagnosticar()">
                <strong>x</strong>
              </label>
            </div>
          </div>  

          <hr style="margin:0px">
          
          <div class="modal-body px-3 text-start">
            <div v-for="req in reqsDiagnosticarMasivo" :key="req.Requerimiento.r_requerimiento_id">
              <!--RESUMEN REQUERIMIENTO-->
              <div class="container b-color-status-grey">
                <small class="d-block p-1">Requerimiento: <b>{{ req.Requerimiento.r_requerimiento_folio }}</b></small>
                <small class="d-block p-1">
                  {{ req.Recinto.r_recinto_nombre }} / {{ req.Lugar.r_lugar_nombre }} / {{ req.Item.r_item_nombre }} / {{ req.Problema.r_problema_nombre }}
                </small>
              </div>

              <!--CORRESPONDE-->
              <div class="mb-2 pt-2 text-start">
                <label class="form-label">Corresponde: </label>
                <select  class="form-select" :ref="'corresponde'+req.Requerimiento.r_requerimiento_id"
                  @change="changeCorresponde(req.Requerimiento.r_requerimiento_id)">
                    <option :value = 1 >CORRESPONDE</option>
                    <option :value = 0 >NO CORRESPONDE</option>
                    <option :value = 2 >SOLUCIONADO EN VISITA</option>
                    <option :value = 3 >RE-INSPECCIÓN</option>
                </select>
              </div>

              <div class="mb-2 pb-3 text-start">
                <label class="form-label">Diagnóstico</label>
                <textarea 
                  class="form-control" 
                  row="3" :ref="'diagnostico'+req.Requerimiento.r_requerimiento_id"
                  :value="req.Requerimiento.r_requerimiento_detalle_problema"></textarea>
              </div>

              <!--INSTRUCCIÓN-->
              <div class="pb-3 text-start" :ref="'div-instruccion'+req.Requerimiento.r_requerimiento_id">
                <label class="form-label">Instrucción</label>
                <textarea  
                  :id="'instruccion-'+req.Requerimiento.r_requerimiento_id"
                  :ref="'instruccion'+req.Requerimiento.r_requerimiento_id"
                  class="form-control" row="3"></textarea>
              </div>

              <!--SOLUCIONADO EN VISITA-->
              <div class="pb-3 text-start d-none" :ref="'div-solucion'+req.Requerimiento.r_requerimiento_id">
                <label class="form-label">Solución</label>
                <textarea
                  :id="'solucion'+req.Requerimiento.r_requerimiento_id" 
                  class="form-control" 
                  :ref="'solucion'+req.Requerimiento.r_requerimiento_id"  row="3"></textarea>
              </div>

              <!--NO CORRESPONDE-->
              <div class="pb-3 text-start d-none" :ref="'div-nocorresponde'+req.Requerimiento.r_requerimiento_id">
                <label class="form-label">Dejar Requerimiento: </label>
                <div class="form-check ps-4 pb-1">
                  <input class="form-check-input" 
                    type="radio" 
                    :name="'tipoCierre'+req.Requerimiento.r_requerimiento_id" 
                    :ref="'conFirma'+req.Requerimiento.r_requerimiento_id"
                  >
                  <label class="form-check-label ps-3">
                    Cerrado con firma
                  </label>
                </div>

                <div class="form-check ps-4 pb-1">
                  <input class="form-check-input" 
                    type="radio" 
                    :name="'tipoCierre'+req.Requerimiento.r_requerimiento_id" 
                    :ref="'sinFirma'+req.Requerimiento.r_requerimiento_id"
                  >
                  <label class="form-check-label ps-3">
                    Cerrado sin firma
                  </label>
                </div>

                <div class="form-check ps-4 pb-1">
                  <input class="form-check-input" 
                    type="radio" 
                    :name="'tipoCierre'+req.Requerimiento.r_requerimiento_id" 
                    :ref="'noCorresponde'+req.Requerimiento.r_requerimiento_id"
                  >
                  <label class="form-check-label ps-3 pb-1">
                    Inspeccionado no corresponde
                  </label>
                </div>
              </div>

              <div class="mb-3" align="left">
                <label class="form-label">Adjuntar</label>
                <div>
                  <file-upload
                    :name="'upload_' + req.Requerimiento.r_requerimiento_id"
                    :ref="'upload_' + req.Requerimiento.r_requerimiento_id"
                    :maximum="3"
                    :multiple="true"
                    v-model="files[req.Requerimiento.r_requerimiento_id]"
                    @input-file="inputFile"
                    @input-filter="inputFilter">
                    <div class="btn btn-secondary">
                      <span class=""><font-awesome-icon icon="folder-open"/> Seleccione Archivo</span>
                    </div>
                  </file-upload>
                </div>
                <ul>
                  <li v-for="file in files[req.Requerimiento.r_requerimiento_id]" v-bind:key="file.name">
                    {{ file.name }}
                    <a class="p-3" @click.prevent="removeFile(req, file)" style="color: #ff2c2c;">
                      <font-awesome-icon icon="trash"/>
                    </a>
                  </li>
                </ul>
              </div>

              <hr class="pb-3" style="margin:0px">
            </div>
          </div>

          <div class="modal-footer px-3">
            <div class="row px-3 pb-3 justify-content-end">
              <button type="button" class="btn" @click="diagnosticaMasivo()" style="background-color: #5CB85C;color: white;">
                Aceptar
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import { defineComponent } from "vue";
  import { useBaseConocimientoStore } from '@/store/baseConocimientoStore';
  import { useOiTableStore } from '@/store/oiTableStore';
  import { storeToRefs } from "pinia";
  import VueUploadComponent from 'vue-upload-component';

  export default defineComponent({
    name: 'ModalDiagnosticarMasivo',
    components: { FileUpload: VueUploadComponent },
    data: function () {
      return {
        files: []
      }
    },
    methods:{
      closeDiagnosticar () {
        this.files = [];
        this.$emit('close');
      },
      inputFile: function (newFile, oldFile) {
        if (newFile && !oldFile) {
          if (!/\.(jpeg|jpe|jpg|png|webp|pdf)$/i.test(newFile.name)) {
            return prevent()
          } else {
            newFile.blob = '';
            var reader = new FileReader();
            reader.readAsDataURL(newFile.file);
            reader.onloadend = function() {
              newFile.base64String = reader.result.replace('data:', '').replace(/^.+,/, '');
           }
          }
        }
      },
      inputFilter: function (newFile, oldFile, prevent) {
        if (newFile && !oldFile) {
          if (!/\.(jpeg|jpe|jpg|png|webp|pdf)$/i.test(newFile.name)) {
            alert('Tipo de archivos soportados jpeg jpe jpg png pdf');
            return prevent()
          }
        }

        if (newFile && oldFile) {
          // update
        }

        if (!newFile && oldFile) {
          // remove
        }
      },
      removeFile(req, file) {
        const index = this.files[req.Requerimiento.r_requerimiento_id].indexOf(file);
        if (index !== -1) {
          this.files[req.Requerimiento.r_requerimiento_id].splice(index, 1);
        }
      },
      diagnosticaMasivo(){
        let validacion = true;
        try{
          this.reqsDiagnosticarMasivo.forEach(req => {
            let id_rec = req.Requerimiento.r_requerimiento_id;
            let corresponde = this.$refs['corresponde'+id_rec][0].value;
            let instruccion = this.$refs['instruccion'+id_rec][0].value;
            
            if(instruccion == '' && corresponde != '0' && corresponde != '2'){
              validacion = false;
              throw new Error('Debe ingresar una instrucción para el Requerimiento '+req.Requerimiento.r_requerimiento_folio);
            }

            let diagnostico = this.$refs['diagnostico'+id_rec][0].value;
            if(diagnostico == ''){
              validacion = false;
              throw new Error('Debe ingresar un diagnóstico para el requerimiento '+req.Requerimiento.r_requerimiento_folio);
            }

            let solucion = this.$refs['solucion'+id_rec][0].value;
            if(corresponde == 2 && solucion == ''){
              validacion = false;
              throw new Error('Debe ingresar una solución para el requerimiento '+req.Requerimiento.r_requerimiento_folio);
            }

            req.diagnostico = {};
            req.diagnostico.corresponde = corresponde;
            req.diagnostico.diagnostico = diagnostico;
            req.diagnostico.instruccion = instruccion;
            if(corresponde == 2){
              req.diagnostico.solucion = solucion;
            } else {
              req.diagnostico.solucion = "";
            }

            req.diagnostico.recinto = req.Recinto.r_recinto_id;
            req.diagnostico.lugar = req.Lugar.r_lugar_id;
            req.diagnostico.item = req.Item.r_item_id;
            req.diagnostico.problema = req.Problema.r_problema_id;

            if(corresponde == 0){
              if(this.$refs['conFirma'+id_rec][0].checked){
                req.diagnostico.opcionCierre = 3; //Con Firma
              }else if(this.$refs['sinFirma'+id_rec][0].checked){
                req.diagnostico.opcionCierre = 1; //Sin Firma
              } else {
                req.diagnostico.opcionCierre = 2; //No corresponde
              }
            }
            
            //FALTAN LOS ARCHIVOS
            req.diagnostico.archivos = this.files[id_rec];
            
            this.$refs['instruccion'+id_rec][0].value = '';
            this.$refs['solucion'+id_rec][0].value = '';
          });
        }catch(error){
          alert(error);
        }
        
        if(validacion){
          const tableStore = useOiTableStore();
          this.$parent.closeModalDiagnosticarMasivo();
          tableStore.diagnosticaOiMasivo();
        }
      },
      cambiaSelector(tipo){
        const baseConocimientoStore = useBaseConocimientoStore();
        var valor = (tipo == 'item') ? this.$refs.item.value : this.$refs.problema.value;
        baseConocimientoStore.cambiaSelectorBaseConocimiento(tipo, valor);
      },
      changeCorresponde(id_rec){
        let classHide = 'pb-3 text-start d-none';
        let classShow = 'pb-3 text-start';

        switch(this.$refs['corresponde'+id_rec][0].value){
          case '0': //NO CORRESPONDE
            this.$refs['div-instruccion'+id_rec][0].className = classHide;
            this.$refs['div-solucion'+id_rec][0].className = classHide;
            this.$refs['div-nocorresponde'+id_rec][0].className = classShow;
            break;
          case '1': //CORRESPONDE
          case '3': // RE-INSPECCION
            this.$refs['div-instruccion'+id_rec][0].className = classShow;
            this.$refs['div-solucion'+id_rec][0].className = classHide;
            this.$refs['div-nocorresponde'+id_rec][0].className = classHide;
            break;
          case '2': //SOL VISITA
            this.$refs['div-instruccion'+id_rec][0].className = classHide;
            this.$refs['div-solucion'+id_rec][0].className = classShow;
            this.$refs['div-nocorresponde'+id_rec][0].className = classHide;
            break;
        }
      },
      onFileChanged() {
        const file = this.$refs.adjunto
      }
    },
    setup() {
      const baseConocimientoStore = useBaseConocimientoStore();
      const tableStore = useOiTableStore();
      
      const { reqsDiagnosticarMasivo, oiDiagnosticar } = storeToRefs(tableStore);
      const { recintos, lugares, itemsFiltrados, problemasFiltrados } = storeToRefs(baseConocimientoStore);
      return { recintos, lugares, itemsFiltrados, problemasFiltrados, reqsDiagnosticarMasivo, oiDiagnosticar  }
    }
  });
</script>

<style scoped>
  .labelGris{
    color:#818181 !important
  }
  .modal-header{
    display: block !important;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 120%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
  }

  .modal-wrapper {
    display: table-cell;
  }

  .modal-container {
    width: 350px;
    margin: 0px auto;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
  }

  .modal-header h3 {
    margin-top: 0;
    color: #42b983;
  }

  .modal-body {
    margin-top: 20px;
    height: 530px;
    overflow-y:auto;
  }

  .b-color-status-grey{
    background-color: #EFEFEF;
  }
</style>