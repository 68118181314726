<template>
  <div class="row justify-content-center align-items-center" style="--bs-gutter-x:0rem">
    <div class="col-md-4 pe-0">
      <div class="breadcrumb d-flex justify-content-between">
        <div class="p-2 ps-4 d-flex">
          <font-awesome-icon icon="home"/>
          <div class="ps-3">
            Orden de Inspección
          </div>
        </div>
        <div class="p-2 d-flex">
          <div class="px-2">
            <button class="btn-breadcrumb" v-if="showSearchIcon" key="on" @click="showSearchIcon = false, showFilterIcon = true">
              <font-awesome-icon icon="magnifying-glass" style="color:black;"/>
            </button>
            <button class="btn-breadcrumb" v-else key="off" @click="showSearchIcon = true">
              <font-awesome-icon icon="xmark" style="color:black;"/>
            </button>
          </div>
          <div class="px-2" >
            <button class="btn-breadcrumb" v-if="showFilterIcon" key="on" @click="showFilterIcon = false, showSearchIcon = true">
              <font-awesome-icon icon="filter" style="color:black;"/>
            </button>
            <button class="btn-breadcrumb" v-else key="off" @click="showFilterIcon = true">
              <font-awesome-icon icon="xmark" style="color:black;"/>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row justify-content-center align-items-center ps-3" style="--bs-gutter-x:0rem">
    <div class="col-md-4 px-5" align="left">
      <Transition>
        <div v-if="!showFilterIcon">
          <div class="mb-3">
            <label for="proyectoFilter" class="col-sm-3 col-form-label" style="text-align: left;">Proyecto</label>
            <div class="col-sm-11">
              <select class="form-control" id="proyectoFilter" @change="filtroProyecto($event)" placeholder="Proyecto">
                <option :value = 0>Seleccione...</option>
                <option v-for="project in projects" :key="project.r_proyecto_id" :value="project.r_proyecto_id">
                  {{ project.r_proyecto_nombre}}
                </option>
              </select>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  </div>
  <div class="row justify-content-center align-items-center ps-3" style="--bs-gutter-x:0rem">
    <div class="col-md-4 px-5">
      <Transition>
        <div v-if="!showSearchIcon">
          <div class="input-group">
              <input class="form-control border-end-0 border rounded-pill" type="search" :value="buscar" id="buscarId" @change="filtroBuscar($event)">
              <span class="input-group-append">
                  <button class="btn btn-outline-secondary bg-white border-bottom-0 border rounded-pill ms-n5" type="button">
                    <font-awesome-icon icon="magnifying-glass" style="color:black;"/>
                  </button>
              </span>
          </div>
        </div>
      </Transition>
    </div>
  </div>
  
</template>

<script>
  import { useProjectStore } from '@/store/projectStore'
  import { useOiTableStore } from '@/store/oiTableStore'
  import { storeToRefs } from "pinia";

  export default {
    name: 'Breadcrumb',
    setup() {
      const projectStore = useProjectStore();
      projectStore.getProjectsService();
      const { projects } = storeToRefs(projectStore);
      return { projects }
    },
    methods:{
      filtroProyecto:function(event){
        const oiTableStore = useOiTableStore();
        oiTableStore.getOiTableProjectsById(event.target.value);
      },
      filtroBuscar:function(event){
        this.buscar = event.target.value;
        const oiTableStore = useOiTableStore();
        oiTableStore.getOiTablesBuscar(this.buscar);
      }
    },
    data() {
      return {
        showFilterIcon: true,
        showSearchIcon: true,
        buscar: ''
      };
    }
  }

</script>
  
<style scoped lang="scss">
  .breadcrumb {
    border-bottom: 1px solid #C8C7CC;
    margin-bottom: 20px;
    background-color: #f5f5f5;
  }

  .ms-n5 {
    margin-left: -40px;
  }
  .btn-breadcrumb{
    border: 1px solid transparent;
    background-color: transparent;
    &:focus {
      outline: 0;
    }
  }

</style>
  