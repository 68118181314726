import { defineStore } from 'pinia'
import axios from 'axios'
import { useLoginStore } from './loginStore'
import idbOiTable from '@/api/indexedDBOiService'
import idbOtTable from '@/api/indexedDBOtService'

export const useOiTableStore = defineStore('oiTableStore', {
  state: () => ({
    oiTableList: [],
    reclamosPropiedadList: [],
    error: 1,
    id: 0,
    filtroProjectoId: 0,
    buscar: '',
    loadingOi: false,
    hayCambiosOi: false,
    reqDiagnosticar: [],
    reqsDiagnosticarMasivo: [],
    propietario: [],
    oiDiagnosticar: [],
    oiComprobante: [],
    reqsComprobante: [],
    quienRecibe: true,
    copiaComprobante: false,
    satisfaccion: [],
    configs_oi: JSON.parse(localStorage.getItem('configs')),
  }),
  actions: {
    async getDataOiTable(){
      try {
        if(navigator.onLine){
            console.log('Esta Online trae desde service OI');
            this.getOiTableService();
            this.getSatisfaccionService();
        }else{
          this.getOiTables();
          this.getSatisfaccionLocal();
          if (this.oiTableList === null) {
            const loginStore = useLoginStore();
            loginStore.sesionIniciada = 0;
          }
        }
      }catch (error) {
        console.log(error);
        this.error = 2;
      }
    },
    async saveOiTables(oiTables) {        
      await idbOiTable.saveOiTable(oiTables);
    },
    async getOiTables() {
      this.oiTableList = [];
      let oiTables = await idbOiTable.getOiTable();
      oiTables.forEach(oiTable => {
          this.oiTableList.push(oiTable);
      });
    },
    async saveSatisfaccionBd(satisfaccionRespose){
      await idbOtTable.saveSatisfaccion(satisfaccionRespose);
    },
    async getSatisfaccionLocal(){
      this.satisfaccion = [];
      let satisfaccionBd = await idbOtTable.getSatisfaccion();
      satisfaccionBd.forEach(sat => {
        this.satisfaccion.push(sat);
      });
    },
    async getOiTablesBuscar(buscar){
      this.oiTableList = [];
      this.buscar = buscar;
      let oiTables = await idbOiTable.getOiTableBuscar(this.buscar);
      oiTables.forEach(oiTable => {
        this.oiTableList.push(oiTable);
      });
    },
    async getOiTableProjectsById(projectId){
      this.filtroProjectoId = projectId;
      this.buscar = '';
      this.filtros();
    },
    async filtros(){
      this.oiTableList = [];
      if(this.filtroProjectoId != 0){
        let oiTables = await idbOiTable.getOiTableByProjectId(this.filtroProjectoId);
        oiTables.forEach(oiTable => {
          this.oiTableList.push(oiTable);
        });
      }else{
        this.getOiTables();
      }
    },
    async deleteOiTableLocal(oiTables){
      await idbOiTable.deleteOiTable(oiTables);
    },
    async deleteAllOiTableLocal(){
      await idbOiTable.deleteAllOiTable();
    },
    async getOiTableService(){
      this.loadingOi = true;
      let user = JSON.parse(localStorage.getItem('user'));
      await axios.get(process.env.VUE_APP_PVI_API_URL+"ordenes-de-inspeccion",{
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      }).then((response) => {
        if(response.data.data.length > 0){
          this.deleteAllOiTableLocal();
          localStorage.setItem('ultimaConexion', this.getCurrentDate());
          let oiTables = response.data.data;
          oiTables.forEach(oi => {
            oi.Requerimientos.forEach(Req => {
              Req.checked = false; //agrego ckeck para acciones masivas
            });
            oi.actualizada = 0; //no ha sido actualizada
            //this.deleteOiTableLocal(oi);
            this.saveOiTables(oi)
          });
          this.getOiTables();
          this.hayCambiosOi = false;
        }
        this.loadingOi = false;
      }).catch(error => {
        console.log(error);
        this.getOiTables();
        this.loadingOi = false;
      });
    },
    async getSatisfaccionLocal(){
      this.satisfaccion = [];
      let satisfaccionBd = await idbOtTable.getSatisfaccion();
      satisfaccionBd.forEach(sat => {
        this.satisfaccion.push(sat);
      });
    },
    async getSatisfaccionService(){
      let user = JSON.parse(localStorage.getItem('user'));
      await axios.get(process.env.VUE_APP_PVI_API_URL+"satisfaccion",{
        headers: {
            'Authorization': `Bearer ${user.token}` 
          }
      }).then((response) => {
        if(response.data.data.length > 0){
          let satisfaccion = response.data.data;
          satisfaccion.forEach(sat => {
              this.saveSatisfaccionBd(sat.Satisfaccion);
          });
        }
        this.getSatisfaccionLocal();
      }).catch(error => {
        console.log(error);
        this.getSatisfaccionLocal();
      });
    },
    async cerrarOi(oiComprobante){
      await idbOiTable.deleteOiTable(oiComprobante);
      if(this.oiComprobante.actualizada == 1){
        this.oiComprobante.actualizada = 3 // viene con diagnostico y comprobante
      }else{
        this.oiComprobante.actualizada = 2; // viene con comprobante
      }
      this.saveOiTables(JSON.parse(JSON.stringify(oiComprobante)));
      this.hayCambiosOi = true;
      this.quienRecibe = true;
      this.copiaComprobante = false;
      this.reqsComprobante = [];
      this.oiComprobante = [];
      this.getOiTables();
      alert('Comprobante agregado con éxito, no olvide sincronizar');
    },
    async cierreDistancia(){
      await idbOiTable.deleteOiTable(this.oiComprobante);
      if(this.oiComprobante.actualizada == 1){
        this.oiComprobante.actualizada = 5 // viene con diagnostico y comprobante distancia
      }else{
        this.oiComprobante.actualizada = 4; // viene con comprobante distancia
      }
      this.hayCambiosOi = true;
      this.saveOiTables(JSON.parse(JSON.stringify(this.oiComprobante)));
      this.oiComprobante = [];
      this.getOiTables();
      alert('Se enviará el cierre a distancia cuando se sincronice la solicitud');
    },

    async diagnosticaOi(){
      var id_req = this.reqDiagnosticar.Requerimiento.r_requerimiento_id;
      var diagnostico = this.reqDiagnosticar.diagnostico;
      var diagnosticada = true; 
      this.oiDiagnosticar.Requerimientos.forEach(function(req){
        if(req.Requerimiento.r_requerimiento_id == id_req){
          switch(diagnostico.corresponde){
            case "1":
              req.Requerimiento.icono_estado.descEstado = 'Corresponde';
              req.Requerimiento.icono_estado.icon_pwa = 'circle-check';
              req.Requerimiento.icono_estado.colorLetra = 'green';
              req.Requerimiento.icono_estado.colorBackgroud = '#CFFFD1';
              break;
            case "0":
              req.Requerimiento.icono_estado.descEstado = 'No Corresp.';
              req.Requerimiento.icono_estado.icon_pwa = 'circle-xmark';
              req.Requerimiento.icono_estado.colorLetra = 'green';
              req.Requerimiento.icono_estado.colorBackgroud = '#CFFFD1';
              break;
            case "2":
              req.Requerimiento.icono_estado.descEstado = 'Solucionado Visit.';
              req.Requerimiento.icono_estado.icon_pwa = 'file';
              req.Requerimiento.icono_estado.colorLetra = 'green';
              req.Requerimiento.icono_estado.colorBackgroud = '#CFFFD1';
              break;
            case "3":
              req.Requerimiento.icono_estado.descEstado = 'En Consulta';
              req.Requerimiento.icono_estado.icon_pwa = 'eye';
              req.Requerimiento.icono_estado.colorLetra = 'green';
              req.Requerimiento.icono_estado.colorBackgroud = '#CFFFD1';
              break;
          }
          req.diagnostico = diagnostico;
        }
        if(req.Requerimiento.icono_estado.descEstado == 'Pendiente'){
          diagnosticada = false; 
        }
      });
      
      this.oiDiagnosticar.OrdenInspeccion.inspeccionada = diagnosticada;
      this.oiDiagnosticar.actualizada = 1; // viene uno o mas diagnosticos
      this.oiDiagnosticar.tiene_reqs_para_conformidad = true;
      await idbOiTable.deleteOiTable(this.oiDiagnosticar);
      
      this.saveOiTables(JSON.parse(JSON.stringify(this.oiDiagnosticar)));
      this.hayCambiosOi = true;
      this.oiDiagnosticar = [];
      this.reqDiagnosticar = [];
      alert('Requerimiento diagnosticado con éxito, no olvide sincronizar...');
      this.getOiTables();
    },
    async diagnosticaOiMasivo(){
      //FALTA TODA ESTA FUNCIÓN
      let oi = this.oiDiagnosticar[0];
      this.reqsDiagnosticarMasivo.forEach(function(req_masivo){
        var id_req = req_masivo.Requerimiento.r_requerimiento_id;
        var diagnostico = req_masivo.diagnostico;
        
        oi.Requerimientos.forEach(function(req_diagnosticar){
          if(req_diagnosticar.Requerimiento.r_requerimiento_id == id_req){
            switch(diagnostico.corresponde){
              case "1":
                req_diagnosticar.Requerimiento.icono_estado.descEstado = 'Corresponde';
                req_diagnosticar.Requerimiento.icono_estado.icon_pwa = 'circle-check';
                req_diagnosticar.Requerimiento.icono_estado.colorLetra = 'green';
                req_diagnosticar.Requerimiento.icono_estado.colorBackgroud = '#CFFFD1';
                break;
              case "0":
                req_diagnosticar.Requerimiento.icono_estado.descEstado = 'No Corresp.';
                req_diagnosticar.Requerimiento.icono_estado.icon_pwa = 'circle-xmark';
                req_diagnosticar.Requerimiento.icono_estado.colorLetra = 'green';
                req_diagnosticar.Requerimiento.icono_estado.colorBackgroud = '#CFFFD1';
                break;
              case "2":
                req_diagnosticar.Requerimiento.icono_estado.descEstado = 'Solucionado Visit.';
                req_diagnosticar.Requerimiento.icono_estado.icon_pwa = 'file';
                req_diagnosticar.Requerimiento.icono_estado.colorLetra = 'green';
                req_diagnosticar.Requerimiento.icono_estado.colorBackgroud = '#CFFFD1';
                break;
              case "3":
                req_diagnosticar.Requerimiento.icono_estado.descEstado = 'En Consulta';
                req_diagnosticar.Requerimiento.icono_estado.icon_pwa = 'eye';
                req_diagnosticar.Requerimiento.icono_estado.colorLetra = 'green';
                req_diagnosticar.Requerimiento.icono_estado.colorBackgroud = '#CFFFD1';
                break;
            }
            req_diagnosticar.diagnostico = diagnostico;
          }
          
        });
      });

      let diagnosticada = true;
      oi.Requerimientos.forEach(function(req){
        if(req.Requerimiento.icono_estado.descEstado == 'Pendiente'){
          diagnosticada = false; 
        }
      });

      oi.OrdenInspeccion.inspeccionada = diagnosticada;
      oi.actualizada = 1; // viene uno o mas diagnosticos
      oi.tiene_reqs_para_conformidad = true;
      await idbOiTable.deleteOiTable(oi);
      
      this.saveOiTables(JSON.parse(JSON.stringify(oi)));
      this.hayCambiosOi = true;
      this.oiDiagnosticar = [];
      this.reqsDiagnosticarMasivo = [];
      alert('Requerimiento(s) diagnosticado(s) con éxito, no olvide sincronizar...');
      this.getOiTables();

    },
    async sincronizarOi(){
      let user = JSON.parse(localStorage.getItem('user'));
      await axios.post(process.env.VUE_APP_PVI_API_URL+'sincronizaPwaOi',{ data: this.oiTableList }, {
        headers: {
          'Authorization': `Bearer ${user.token}` 
        }
      })
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        alert('Hubo un error en la sincronización. Contacte a mesa de ayuda');
        console.log(error);
      });

      //this.getOiTableService();
      this.getDataOiTable();
    },
    getCurrentDate(){
      var today = new Date();
      var hour = today.getHours() < 10 ? "0"+today.getHours() : today.getHours();
      var min = today.getMinutes() < 10 ? "0"+today.getMinutes() : today.getMinutes();
      var month = '';
      var todayComplete = today.getDate()+"/"+(today.getMonth()+1)+"/"+today.getFullYear();
      var lastConexion =  todayComplete+" a las "+hour+":"+min;
      return lastConexion;
    }
  },
})