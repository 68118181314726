import idb from '@/api/configDbService'

export default {
	async deleteOtTable(otTable) {
		let db = await idb.getDb();
		return new Promise(resolve => {
			let trans = db.transaction(['otTable'],'readwrite');
			trans.oncomplete = () => {
				resolve();
			};

			let store = trans.objectStore('otTable');
			store.delete(otTable.r_orden_trabajo_id);
		});	
	},
	async deleteAllOtTable(){
		let db = await idb.getDb();
		return new Promise(resolve => {
			let trans = db.transaction(['otTable'],'readwrite');
			trans.oncomplete = () => {
				resolve();
			};

			let store = trans.objectStore('otTable');
			store.clear();
		});	
	},
	async getOtTable() {
		let db = await idb.getDb();
		return new Promise(resolve => {
			let trans = db.transaction(['otTable'],'readonly');
			trans.oncomplete = () => {
				resolve(otTables);
			};
			
			let store = trans.objectStore('otTable');
			let otTables = [];
			
			store.openCursor().onsuccess = e => {
				let cursor = e.target.result;
				if (cursor) {
					otTables.push(cursor.value)
					cursor.continue();
				}
			};
		});
	},
	async getOtTableByProjectId(projectId){
		let db = await idb.getDb();
		return new Promise(resolve => {
			let trans = db.transaction(['otTable'],'readonly');
			trans.oncomplete = () => {
				resolve(otTables);
			};
			
			let store = trans.objectStore('otTable');
			let otTables = [];
			
			store.openCursor().onsuccess = e => {
				let cursor = e.target.result;
				if (cursor) {
					if(cursor.value.Proyecto.r_proyecto_id == projectId){
						otTables.push(cursor.value);
					}
					cursor.continue();
				}
			};
		});
	},
	async getOtTableByProjectIdVistaPor(filtroProjectoId, filtroVistaPor){
		let compare = (filtroVistaPor === "true");
		let db = await idb.getDb();
		return new Promise(resolve => {
			let trans = db.transaction(['otTable'],'readonly');
			trans.oncomplete = () => {
				resolve(otTables);
			};
			
			let store = trans.objectStore('otTable');
			let otTables = [];
			
			store.openCursor().onsuccess = e => {
				let cursor = e.target.result;
				if (cursor) {
					if(cursor.value.Proyecto.r_proyecto_id == filtroProjectoId && cursor.value.terminada === compare){
						otTables.push(cursor.value);
					}
					cursor.continue();
				}
			};
		});
	},
	async getOtTableVistaPor(terminada){
		let compare = (terminada === "true");
		let db = await idb.getDb();
		return new Promise(resolve => {
			let trans = db.transaction(['otTable'],'readonly');
			trans.oncomplete = () => {
				resolve(otTables);
			};
			
			let store = trans.objectStore('otTable');
			let otTables = [];
			
			store.openCursor().onsuccess = e => {
				let cursor = e.target.result;
				if (cursor) {
					if(cursor.value.terminada === compare){
						otTables.push(cursor.value);
					}
					cursor.continue();
				}
			};
		});
	},
	async getOtTableBuscar(buscar){
		let db = await idb.getDb();
		return new Promise(resolve => {
			let trans = db.transaction(['otTable'],'readonly');
			trans.oncomplete = () => {
				resolve(otTables);
			};
			
			let store = trans.objectStore('otTable');
			let otTables = [];
			
			store.openCursor().onsuccess = e => {
				let cursor = e.target.result;
				if (cursor) {
					if (cursor.value.Etapa.r_etapa_nombre.toLowerCase().indexOf(buscar.toLowerCase()) != -1 || 
						cursor.value.OrdenTrabajo.r_orden_trabajo_folio.toLowerCase().indexOf(buscar.toLowerCase()) != -1 || 
						cursor.value.OrdenTrabajo.fecha_emision.toLowerCase().indexOf(buscar.toLowerCase()) != -1 || 
						cursor.value.OrdenTrabajo.fecha_inicio.toLowerCase().indexOf(buscar.toLowerCase()) != -1 || 
						cursor.value.Propiedad.r_propiedad_direccion.toLowerCase().indexOf(buscar.toLowerCase()) != -1 || 
						cursor.value.Propietario.r_propietario_nombre.toLowerCase().indexOf(buscar.toLowerCase()) != -1 ||
						cursor.value.Proyecto.r_proyecto_nombre.toLowerCase().indexOf(buscar.toLowerCase()) != -1)
					{
						otTables.push(cursor.value)
					}
					cursor.continue();
				}
			};
		});
	},
	async saveOtTable(OtTable) {
		let db = await idb.getDb();
		return new Promise(resolve => {
			let trans = db.transaction(['otTable'],'readwrite');
			trans.oncomplete = () => {
				resolve();
			};
			let store = trans.objectStore('otTable');
			store.put(OtTable);
		});
	},
	async saveSatisfaccion(satisfaccion){
		let db = await idb.getDb();
		return new Promise(resolve => {
			let trans = db.transaction(['satisfaccion'],'readwrite');
			trans.oncomplete = () => {
				resolve();
			};
			let store = trans.objectStore('satisfaccion');
			store.put(satisfaccion);
		});
	},
	async getSatisfaccion(){
		let db = await idb.getDb();
		return new Promise(resolve => {
			let trans = db.transaction(['satisfaccion'],'readonly');
			trans.oncomplete = () => {
				resolve(satisfaccion);
			};
			
			let store = trans.objectStore('satisfaccion');
			let satisfaccion = [];
			
			store.openCursor().onsuccess = e => {
				let cursor = e.target.result;
				if (cursor) {
					satisfaccion.push(cursor.value)
					cursor.continue();
				}
			};
		});
	},

	async deleteMotivo(motivoReprogramacion) {
		let db = await idb.getDb();
		return new Promise(resolve => {
			let trans = db.transaction(['motivo_reprogramacion'],'readwrite');
			trans.oncomplete = () => {
				resolve();
			};
			let store = trans.objectStore('motivo_reprogramacion');
			store.delete(motivoReprogramacion.r_motivo_id);
		});
	},
	async saveMotivo(motivoReprogramacion) {
		let db = await idb.getDb();
		return new Promise(resolve => {
			let trans = db.transaction(['motivo_reprogramacion'],'readwrite');
			trans.oncomplete = () => {
				resolve();
			};
			let store = trans.objectStore('motivo_reprogramacion');
			store.put(motivoReprogramacion);
		});
	},
	async getMotivos() {
		let db = await idb.getDb();
		return new Promise(resolve => {
			let trans = db.transaction(['motivo_reprogramacion'],'readonly');
			trans.oncomplete = () => {
				resolve(motivoReprogramacion);
			};
			
			let store = trans.objectStore('motivo_reprogramacion');
			let motivoReprogramacion = [];
			
			store.openCursor().onsuccess = e => {
				let cursor = e.target.result;
				if (cursor) {
					motivoReprogramacion.push(cursor.value)
					cursor.continue();
				}
			};
		});
	},

	async deleteHoraValida(horaValida) {
		let db = await idb.getDb();
		return new Promise(resolve => {
			let trans = db.transaction(['horas_validas'],'readwrite');
			trans.oncomplete = () => {
				resolve();
			};
			let store = trans.objectStore('horas_validas');
			store.delete(horaValida.r_hora_id);
		});
	},
	async saveHoraValida(horaValida) {
		let db = await idb.getDb();
		return new Promise(resolve => {
			let trans = db.transaction(['horas_validas'],'readwrite');
			trans.oncomplete = () => {
				resolve();
			};
			let store = trans.objectStore('horas_validas');
			store.put(horaValida);
		});
	},
	async getHorasValidas() {
		let db = await idb.getDb();
		return new Promise(resolve => {
			let trans = db.transaction(['horas_validas'],'readonly');
			trans.oncomplete = () => {
				resolve(horaValida);
			};
			
			let store = trans.objectStore('horas_validas');
			let horaValida = [];
			
			store.openCursor().onsuccess = e => {
				let cursor = e.target.result;
				if (cursor) {
					horaValida.push(cursor.value)
					cursor.continue();
				}
			};
		});
	}
}