import { defineStore } from 'pinia'
import axios from 'axios'
import idbProject from '@/api/indexedDBProjectService'

export const useProjectStore = defineStore('projectStore', {
  state: () => ({
    projects: [],
  }),
  actions: {
    async deleteProject(project) {
        await idbProject.deleteProject(project); 
    },
    async getProjects() {
        this.projects = [];
        let projects = await idbProject.getProjects();
        projects.forEach(pro => {
            this.projects.push(pro);
        });
    },
    async saveProject(project) {        
        await idbProject.saveProject(project);
    },
    async getProjectsService(){
        let user = JSON.parse(localStorage.getItem('user'));
        if(navigator.onLine){
            await axios.get(process.env.VUE_APP_PVI_API_URL+"proyectos-por-usuario",{
                headers: {
                    'Authorization': `Bearer ${user.token}` 
                  }
            }).then((response) => {
                if(response.data.data.length > 0){
                    let projects = response.data.data;
                    projects.forEach(pro => {
                        this.saveProject(pro.Proyecto)
                    });
                }
                this.getProjects();
            }).catch(error => {
                console.log(error);
                this.getProjects();
            });
        }else{
            console.log('Proyectos del local');
            this.getProjects();
        }
        
    }
  },
})