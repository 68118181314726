import { defineStore } from 'pinia'
import axios from 'axios'
import { useLoginStore } from './loginStore'
import idbOtTable from '@/api/indexedDBOtService'

export const useOtTableStore = defineStore('otTableStore', {
  state: () => ({
    otTableList: [],
    reclamosPropiedadList: [],
    error: 1,
    id: 0,
    filtroProjectoId: 0,
    filtroVistaPor: 0,
    buscar: '',
    quienRecibe: true,
    copiaConformidad: false,
    reqsTerminar: [],
    reqsConformidad: [],
    propietario: [],
    otConformidad: [],
    otReprogramar: [],
    otTerminar: [],
    satisfaccion: [],
    loadingOt: false,
    hayCambios: false,
    motivosReprogramacion: [],
    horasValidas: []
  }),
  actions: {
    async getDataOtTable(){
      try {
        if(navigator.onLine){
            console.log('Esta Online trae desde service OT');
            this.getOtTableService();
            this.getSatisfaccionService();
        }else{
          this.loadingOt = false;
          this.getOtTables();
          this.getSatisfaccionLocal();
          if (this.otTableList === null) {
            const loginStore = useLoginStore();
            loginStore.sesionIniciada = 0;
          }
        }
      }catch (error) {
        console.log(error);
        this.error = 2;
      }
    },
    async saveOtTables(otTables) {        
      await idbOtTable.saveOtTable(otTables);
    },
    async saveSatisfaccionBd(satisfaccionRespose){
      await idbOtTable.saveSatisfaccion(satisfaccionRespose);
    },
    async getSatisfaccionLocal(){
      this.satisfaccion = [];
      let satisfaccionBd = await idbOtTable.getSatisfaccion();
      satisfaccionBd.forEach(sat => {
        this.satisfaccion.push(sat);
      });
    },
    async getOtTables() {
      this.otTableList = [];
      let otTables = await idbOtTable.getOtTable();
      otTables.forEach(otTable => {
          this.otTableList.push(otTable);
      });
      this.loadingOt = false;
    },
    async getOtTablesBuscar(buscar){
      this.otTableList = [];
      this.buscar = buscar;
      let otTables = await idbOtTable.getOtTableBuscar(this.buscar);
      otTables.forEach(otTable => {
        this.otTableList.push(otTable);
      });
    },
    async getOtTableProjectsById(projectId){
      this.filtroProjectoId = projectId;
      this.buscar = '';
      this.filtros();
    },
    async getOtTablesByVistaPor(terminada){
      this.filtroVistaPor = terminada;
      this.buscar = '';
      this.filtros();
    },
    async filtros(){
      this.otTableList = [];
      if(this.filtroProjectoId != 0 && this.filtroVistaPor != 0){
        let otTables = await idbOtTable.getOtTableByProjectIdVistaPor(this.filtroProjectoId, this.filtroVistaPor);
        otTables.forEach(otTable => {
          this.otTableList.push(otTable);
        });
      }else if(this.filtroProjectoId != 0){
        let otTables = await idbOtTable.getOtTableByProjectId(this.filtroProjectoId);
        otTables.forEach(otTable => {
          this.otTableList.push(otTable);
        });
      }else if(this.filtroVistaPor != 0){
        let otTables = await idbOtTable.getOtTableVistaPor(this.filtroVistaPor);
        otTables.forEach(otTable => {
          this.otTableList.push(otTable);
        });
      }else{
        this.getOtTables();
      }
    },
    async deleteAllOtTableLocal(){
      await idbOtTable.deleteAllOtTable();
    },
    async getOtTableService(){
      this.loadingOt = true;
      let user = JSON.parse(localStorage.getItem('user'));
      await axios.get(process.env.VUE_APP_PVI_API_URL+"ordenes-de-trabajo",{
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      }).then((response) => {
        if(response.data.data.length > 0){
          this.deleteAllOtTableLocal();
          localStorage.setItem('ultimaConexion', this.getCurrentDate());
          let otTables = response.data.data;
          otTables.forEach(ot => {
            ot.Requerimientos.forEach(Req => {
              Req.checked = false; //agrego ckeck para acciones masivas
            });
            ot.actualizada = 0; //no ha sido actualizada
            this.saveOtTables(ot)
          });
          this.getOtTables();
          this.hayCambios = false;
        }
        this.loadingOt = false;
      }).catch(error => {
        console.log(error);
        this.getOtTables();
        this.loadingOt = false;
      });
    },
    async getSatisfaccionService(){
      let user = JSON.parse(localStorage.getItem('user'));
      await axios.get(process.env.VUE_APP_PVI_API_URL+"satisfaccion",{
        headers: {
            'Authorization': `Bearer ${user.token}` 
          }
      }).then((response) => {
        if(response.data.data.length > 0){
          let satisfaccion = response.data.data;
          satisfaccion.forEach(sat => {
              this.saveSatisfaccionBd(sat.Satisfaccion);
          });
        }
        this.getSatisfaccionLocal();
      }).catch(error => {
        console.log(error);
        this.getSatisfaccionLocal();
      });
    },
    async terminarReq(date){
      let otTerminadaCompleta = true;
      this.otTerminar.Requerimientos.forEach(req => {
        this.reqsTerminar.forEach(function(reqTerminar){
          if(req.Requerimiento.r_requerimiento_id == reqTerminar.Requerimiento.r_requerimiento_id){
            req.Requerimiento.fecha_termino = date.toISOString();
            req.Requerimiento.terminado = true;
          }
        });
        if(!req.Requerimiento.terminado){
          otTerminadaCompleta = false;
        }
      });
      if(otTerminadaCompleta){
        this.otTerminar.terminada = true;
      }
      this.otTerminar.actualizada = 1; // viene con fecha de termino
      await idbOtTable.deleteOtTable(this.otTerminar);
      this.saveOtTables(JSON.parse(JSON.stringify(this.otTerminar)));
      this.hayCambios = true;
      this.reqsTerminar = [];
      this.otTerminar = [];
      alert('Requerimiento(s) terminados con éxito, no olvide sincronizar');
    },
    async cerrarOt(otConformidad){
      await idbOtTable.deleteOtTable(otConformidad);
      if(this.otConformidad.actualizada == 1){
        this.otConformidad.actualizada = 3 // viene con termino y conformidad
      }else{
        this.otConformidad.actualizada = 2; // viene con conformidad
      }
      this.saveOtTables(JSON.parse(JSON.stringify(otConformidad)));
      this.hayCambios = true;
      this.quienRecibe = true;
      this.copiaConformidad = false;
      this.reqsConformidad = [];
      this.otConformidad = [];
      this.getOtTables();
      alert('Conformidad agregada con éxito, no olvide sincronizar');
    },
    async cierreDistancia(){
      if (this.otConformidad.actualizada == 1) {
        this.otConformidad.actualizada = 5 // viene con termino y conformidad distancia
      } else {
        this.otConformidad.actualizada = 4; // viene con conformidad distancia
      }
      await idbOtTable.deleteOtTable(this.otConformidad);
      this.saveOtTables(JSON.parse(JSON.stringify(this.otConformidad)));
      this.hayCambios = true;
      this.otConformidad = [];
      this.getOtTables();
      alert('Se enviará el cierre a distancia cuando se sincronice la solicitud');
    },
    async sincronizarOt(){
      let user = JSON.parse(localStorage.getItem('user'));
      await axios.post(process.env.VUE_APP_PVI_API_URL+'sincronizaPwa',{ data: this.otTableList }, {
        headers: {
          'Authorization': `Bearer ${user.token}` 
        }
      })
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        alert('Hubo un error en la sincronización. Contacte a mesa de ayuda');
        console.log(error);
      }); 
      this.getDataOtTable();
    },

    async enviarEmailReprogramarOT(){
      var reprogramar = this.otReprogramar.reprogramar;

      this.otReprogramar.actualizada = 6; // viene con reprogramación
      this.hayCambios = true;
      this.saveOtTables(JSON.parse(JSON.stringify(this.otReprogramar)));

      this.otReprogramar = [];
      this.getOtTables();
      alert('Se reprogramará la ot cuando se sincronice la solicitud');
    },

    async deleteMotivo(motivoReprogramacion){
      await idbOtTable.deleteMotivo(motivoReprogramacion);
    },
    async saveMotivo(motivoReprogramacion) {        
      await idbOtTable.saveMotivo(motivoReprogramacion);
    },
    async getMotivo() {
      let motivos = await idbOtTable.getMotivos();
      motivos.forEach(motivoReprogramacion => {
        this.motivosReprogramacion.push(motivoReprogramacion);
      });
    },
    async getMotivoReprogramacionService(){
      let user = JSON.parse(localStorage.getItem('user'));
      await axios.get(process.env.VUE_APP_PVI_API_URL + "motivo-reprogramacion",{
        headers: {
          'Authorization': `Bearer ${user.token}` 
        }
      }).then((response) => {
        localStorage.setItem('ultimaConexion', this.getCurrentDate());
        
        if (response.data.data.length > 0) {
          let motivos = response.data.data;
          motivos.forEach(motivoReprogramacion => {
              this.deleteMotivo(motivoReprogramacion.Motivo);
              this.saveMotivo(motivoReprogramacion.Motivo);
          });
        }
        this.getMotivo();
      }).catch(error => {
        console.log(error);
        this.getMotivo();
      });
    },
    async getDataMotivo(){
      try {
        if (navigator.onLine) {
            console.log('Esta Online trae desde service Motivo');
            this.getMotivoReprogramacionService();
        } else {
          this.getMotivo();
          if (this.motivosReprogramacion === null) {
            const loginStore = useLoginStore();
            loginStore.sesionIniciada = 0;
          }
        }
      } catch (error) {
        console.log(error);
        this.error = 2;
      }
    },

    async deleteHoraValida(horaValida){
      await idbOtTable.deleteHoraValida(horaValida);
    },
    async saveHoraValida(horaValida) {        
      await idbOtTable.saveHoraValida(horaValida);
    },
    async getHoraValida() {
      let horas = await idbOtTable.getHorasValidas();
      horas.forEach(horaValida => {
        this.horasValidas.push(horaValida);
      });
    },
    async getHoraValidaService(){
      let user = JSON.parse(localStorage.getItem('user'));
      await axios.get(process.env.VUE_APP_PVI_API_URL + "horas-validas",{
        headers: {
          'Authorization': `Bearer ${user.token}` 
        }
      }).then((response) => {
        localStorage.setItem('ultimaConexion', this.getCurrentDate());
        
        if (response.data.data.length > 0) {
          let horas = response.data.data;
          horas.forEach(horaValida => {
              this.deleteHoraValida(horaValida);
              this.saveHoraValida(horaValida);
          });
        }
        this.getHoraValida();
      }).catch(error => {
        console.log(error);
        this.getHoraValida();
      });
    },
    async getDataHoraValida(){
      try {
        if (navigator.onLine) {
            console.log('Esta Online trae desde service Hora Valida');
            this.getHoraValidaService();
        } else {
          this.getHoraValida();
          if (this.horasValidas === null) {
            const loginStore = useLoginStore();
            loginStore.sesionIniciada = 0;
          }
        }
      } catch (error) {
        console.log(error);
        this.error = 2;
      }
    },

    getCurrentDate(){
      var today = new Date();
      var hour = today.getHours() < 10 ? "0"+today.getHours() : today.getHours();
      var min = today.getMinutes() < 10 ? "0"+today.getMinutes() : today.getMinutes();
      var month = '';
      var todayComplete = today.getDate()+"/"+(today.getMonth()+1)+"/"+today.getFullYear();
      var lastConexion =  todayComplete+" a las "+hour+":"+min;
      return lastConexion;
    }
  },
})