<template>
  <div class="row justify-content-center align-items-center" style="--bs-gutter-x:0rem">
    <div class="col-md-4 pe-0" align="left">
      <v-offline @detected-condition="amIOnline" online-class="online" offline-class="offline ps-4">
        <template v-if="!online">
          <div class="flex w-full h-full justify-center items-center text-6xl py-2"> 
            <img :src="'/img/barra-wifi.svg'" style="width: 20px;padding-bottom: 3px;"> 
            <strong> No hay conexión a internet. </strong><br> 
            <label class="ps-4"> Última actualización: {{ lastConexion }} </label>
          </div>
        </template>
      </v-offline>
    </div>
  </div>
</template>

<script>
import { VOffline } from 'v-offline';

export default {
  components: {
    VOffline
  },
  data() {
    return {
      online: true
    };
  },
  setup() {
    if(navigator.onLine){
      if(localStorage.getItem('ultimaConexion') != null){
        var lastConexion = localStorage.getItem('ultimaConexion');
      }else{
        var lastConexion = " Necesita una conexión a internet";
      }
    }else{
      if(localStorage.getItem('ultimaConexion') != null){
        var lastConexion = localStorage.getItem('ultimaConexion');
      }else{
        var lastConexion = " Necesita una conexión a internet";
      }
    }
    return { lastConexion }
  },
  methods: {
    amIOnline(e) {
      this.online = e;
    },
  },
}
</script>
<style scoped>
.offline {
    width: 100%;
    height: 100%;
    background-color: #dd8b8b;
}
</style>

