<template>
  <transition name="modal-fade">
    <div class="modal-mask">
      <div class="modal-wrapper pt-2">
        <div class="modal-container modal-dialog-scrollable">

          <div class="modal-header">
            <div class="d-flex justify-content-between">
              <label class="p-3"><strong>Requerimiento a diagnosticar</strong></label>
              <label class="p-3" @click="closeDiagnosticar()">
                <strong>x</strong>
              </label>
            </div>
          </div>  

          <hr style="margin:0px">
          
          <div class="modal-body p-3">
            <div class="mb-3" align="left">
                <label for="corresponde" class="form-label">Corresponde: </label>
                <select class="form-select" ref="corresponde" @change="changeCorresponde()">
                  <option :value = 1 >CORRESPONDE</option>
                  <option :value = 0 >NO CORRESPONDE</option>
                  <option :value = 2 >SOLUCIONADO EN VISITA</option>
                  <option :value = 3 >RE-INSPECCIÓN</option>
                </select>
              </div>

              <div class="mb-3" align="left" v-if="reqDiagnosticar.Recinto">
                <label for="recinto" class="form-label">Recinto</label>
                <select class="form-select" ref="recinto" disabled>
                  <option :key="reqDiagnosticar.Recinto.r_recinto_id" 
                          :value="reqDiagnosticar.Recinto.r_recinto_id">
                    {{ reqDiagnosticar.Recinto.r_recinto_nombre}}
                  </option>
                </select>
              </div>

              <div class="mb-3" align="left" v-if="reqDiagnosticar.Lugar">
                <label for="lugar" class="form-label">Lugar</label>
                <select class="form-select" ref="lugar" disabled>
                  <option :key="reqDiagnosticar.Lugar.r_lugar_id" 
                          :value="reqDiagnosticar.Lugar.r_lugar_id">
                    {{ reqDiagnosticar.Lugar.r_lugar_nombre}}
                  </option>
                </select>
              </div>

              <div class="mb-3" align="left" v-if="reqDiagnosticar.Item">
                <label for="item" class="form-label">Item</label>
                <select class="form-select" ref="item" @change="cambiaSelector('item')">
                  <option v-for="item in itemsFiltrados" 
                        :key="item.r_item_id" 
                        :value="item.r_item_id" 
                        :selected="reqDiagnosticar.Item.r_item_id == item.r_item_id">
                    {{ item.r_item_nombre}}
                  </option>
                </select>
              </div>

              <div class="mb-3" align="left" v-if="reqDiagnosticar.Problema">
                <label for="problema" class="form-label">Problema</label>
                <select class="form-select" ref="problema" @change="cambiaSelector('problema')">
                  <option v-for="problema in problemasFiltrados" 
                        :key="problema.r_problema_id" 
                        :value="problema.r_problema_id"
                        :selected="reqDiagnosticar.Problema.r_problema_id == problema.r_problema_id">
                    {{ problema.r_problema_nombre}}
                  </option>
                </select>
              </div>
              <div class="mb-3" align="left" v-if="reqDiagnosticar.Requerimiento">
                <label for="problema" class="form-label">Diagnóstico</label>
                <textarea 
                  id="diagnostico" 
                  class="form-control" 
                  row="3"
                  ref="diagnostico"
                  :value="reqDiagnosticar.Requerimiento.r_requerimiento_detalle_problema">
                </textarea>
              </div>
              <div class="mb-3" align="left" v-if="reqDiagnosticar">
                <label for="instruccion" class="form-label">Instrucción</label>
                <textarea  id="instruccion" class="form-control" ref="instruccion" row="3"></textarea>
              </div>
              <div class="mb-3" align="left" v-if="reqDiagnosticar && muestraSolucion">
                <label for="solucion" class="form-label">Solución</label>
                <textarea  id="solucion" class="form-control" ref="solucion"  row="3"></textarea>
              </div>

              <div class="mb-3" align="left" v-if="reqDiagnosticar">
                <label class="form-label">Adjuntar</label>
                  <div>
                    <file-upload
                      ref="upload"
                      :maximum="3"
                      :multiple="true"
                      v-model="files"
                      @input-file="inputFile"
                      @input-filter="inputFilter">
                        <div class="btn btn-secondary">
                          <span class=""><font-awesome-icon icon="folder-open"/> Seleccione Archivo</span>
                        </div>
                    </file-upload>
                </div>
                <ul>
                  <li v-for="file in files" v-bind:key="file.name">
                    {{file.name}}
                    <a class="p-3" @click.prevent="this.$refs.upload.remove(file)" style="color: #ff2c2c;">
                      <font-awesome-icon icon="trash"/>
                    </a>
                  </li>
                </ul>
              </div>
          </div>

          <div class="modal-footer px-3">
            <div class="row px-3 pb-3 justify-content-end">
              <button type="button" class="btn" @click="diagnostica()" style="background-color: #5CB85C;color: white;">
                Aceptar
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import { defineComponent } from "vue";
  import { useBaseConocimientoStore } from '@/store/baseConocimientoStore';
  import { useOiTableStore } from '@/store/oiTableStore';
  import { storeToRefs } from "pinia";
  import VueUploadComponent from 'vue-upload-component';

  export default defineComponent({
    name: 'ModalDiagnosticar',
    components: { FileUpload: VueUploadComponent },
    data: function () {
      return {
        files: [],
        muestraSolucion: false
      }
    },
    methods:{
      closeDiagnosticar () {
        this.files = [];
        this.$emit('close');
      },
      inputFile: function (newFile, oldFile) {
        if (newFile && !oldFile) {
          if (!/\.(jpeg|jpe|jpg|png|webp|pdf)$/i.test(newFile.name)) {
            return prevent()
          } else {
            newFile.blob = '';
            var reader = new FileReader();
            reader.readAsDataURL(newFile.file);
            reader.onloadend = function() {
              newFile.base64String = reader.result.replace('data:', '').replace(/^.+,/, '');
            }
          }
        }
      },
      inputFilter: function (newFile, oldFile, prevent) {
        if (newFile && !oldFile) {
          if (!/\.(jpeg|jpe|jpg|png|webp|pdf)$/i.test(newFile.name)) {
            alert('Tipo de archivos soportados jpeg jpe jpg png pdf');
            return prevent()
          }
        }

        if (newFile && oldFile) {
          // update
        }

        if (!newFile && oldFile) {
          // remove
        }
      },
      diagnostica(){
        if(this.$refs.instruccion.value == '' && this.$refs.corresponde.value != 0 && this.$refs.corresponde.value != 2 && this.$refs.corresponde.value != 3){
          alert('Debe ingresar una instrucción');
          return false;
        }
        if(this.$refs.diagnostico.value == ''){
          alert('Debe ingresar un diagnóstico');
          return false;
        }

        if(this.$refs.corresponde.value == 2 && this.$refs.solucion.value == ''){
          alert('Debe ingresar una solución');
          return false;
        }

        const tableStore = useOiTableStore();
        this.reqDiagnosticar.diagnostico = {};
        this.reqDiagnosticar.diagnostico.corresponde = this.$refs.corresponde.value;
        this.reqDiagnosticar.diagnostico.recinto = this.$refs.recinto.value;
        this.reqDiagnosticar.diagnostico.lugar = this.$refs.lugar.value;
        this.reqDiagnosticar.diagnostico.item = this.$refs.item.value;
        this.reqDiagnosticar.diagnostico.problema = this.$refs.problema.value;
        this.reqDiagnosticar.diagnostico.diagnostico = this.$refs.diagnostico.value;
        this.reqDiagnosticar.diagnostico.instruccion = this.$refs.instruccion.value;
        if(this.$refs.corresponde.value == 2){
          this.reqDiagnosticar.diagnostico.solucion = this.$refs.solucion.value;
        } else {
          this.reqDiagnosticar.diagnostico.solucion = "";
        }
        this.reqDiagnosticar.diagnostico.archivos = this.files;
        
        this.$parent.closeModalDiagnosticar();
        if(this.$refs.corresponde.value == 0){
          this.$parent.showModalNoCorresponde();
          this.$refs.instruccion.value = '';
          this.files = [];
        } else {
          tableStore.diagnosticaOi();
          this.$refs.instruccion.value = '';
          this.files = [];
        }
      },
      cambiaSelector(tipo){
        const baseConocimientoStore = useBaseConocimientoStore();
        var valor = (tipo == 'item') ? this.$refs.item.value : this.$refs.problema.value;
        baseConocimientoStore.cambiaSelectorBaseConocimiento(tipo, valor);
      },
      changeCorresponde(){
        if(this.$refs.corresponde.value == 0){
          this.$refs.instruccion.value = '';
          this.$refs.instruccion.disabled = true;
        } else {
          this.$refs.instruccion.disabled = false;
        }

        if(this.$refs.corresponde.value == 2){
          this.muestraSolucion = true;
          this.$refs.instruccion.disabled = true;
        } else {
          this.muestraSolucion = false;
          this.$refs.instruccion.disabled = false;
        }
      },
      onFileChanged() {
        const file = this.$refs.adjunto
      }
    },
    setup() {
      const baseConocimientoStore = useBaseConocimientoStore();
      const tableStore = useOiTableStore();
      
      const { reqDiagnosticar, oiDiagnosticar } = storeToRefs(tableStore);
      const { recintos, lugares, itemsFiltrados, problemasFiltrados } = storeToRefs(baseConocimientoStore);
      return { recintos, lugares, itemsFiltrados, problemasFiltrados, reqDiagnosticar, oiDiagnosticar  }
    }
  });
</script>

<style scoped>
  .labelGris{
    color:#818181 !important
  }
  .modal-header{
    display: block !important;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 120%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
  }

  .modal-wrapper {
    display: table-cell;
  }

  .modal-container {
    width: 350px;
    margin: 0px auto;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
  }

  .modal-header h3 {
    margin-top: 0;
    color: #42b983;
  }

  .modal-body {
    margin-top: 20px;
    height: 530px;
    overflow-y:auto;
  }
</style>