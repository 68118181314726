import { defineStore } from 'pinia'
import axios from 'axios'
import { useLoginStore } from './loginStore'
import idbBaseConocimiento from '@/api/indexedDBBaseConocimientoService'

export const useBaseConocimientoStore = defineStore('baseConocimientoStore', {
  state: () => ({
    recintos: [],
    lugares: [],
    items: [],
    itemsFiltrados: [],
    problemas: [],
    problemasFiltrados: [],
    combinatorias: [],
    error: 1
  }),
  actions: {
    async getDataBaseConocimiento(){
      try {
        if(navigator.onLine){
            console.log('Esta Online trae desde service Base Conocimiento');
            this.getBaseConocimientoService();
        }else{
          this.getBaseConocimiento();
          if (this.recintos === null || this.lugares === null || this.items === null || this.problemas === null) {
            const loginStore = useLoginStore();
            loginStore.sesionIniciada = 0;
          }
        }
      }catch (error) {
        console.log(error);
        this.error = 2;
      }
    },
    /*async deleteRecinto(recinto){
        await idbBaseConocimiento.deleteRecinto(recinto);
    },
    async saveRecinto(recinto) {        
      await idbBaseConocimiento.saveRecinto(recinto);
    },
    async deleteLugar(lugar){
        await idbBaseConocimiento.deleteLugar(lugar);
    },
    async saveLugar(lugar) {        
      await idbBaseConocimiento.saveLugar(lugar);
    },*/
    async deleteItem(item){
      await idbBaseConocimiento.deleteItem(item);
    },
    async saveItem(item) {        
      await idbBaseConocimiento.saveItem(item);
    },
    async deleteProblema(problema){
      await idbBaseConocimiento.deleteProblema(problema);
    },
    async saveProblema(problema) {        
      await idbBaseConocimiento.saveProblema(problema);
    },
    async getBaseConocimiento() {
      this.recintos = [];
      /*let recintos = await idbBaseConocimiento.getRecintos();
      let lugares = await idbBaseConocimiento.getLugares();*/
      let items = await idbBaseConocimiento.getItems();
      let problemas = await idbBaseConocimiento.getProblemas();
      /*recintos.forEach(recinto => {
          this.recintos.push(recinto);
      });
      lugares.forEach(lugar => {
        this.lugares.push(lugar);
      });*/
      items.forEach(item => {
        this.items.push(item);
      });
      problemas.forEach(problema => {
        this.problemas.push(problema);
      });
    },
    async getBaseConocimientoService(){
      let user = JSON.parse(localStorage.getItem('user'));
      await axios.get(process.env.VUE_APP_PVI_API_URL+"base-conocimiento",{
        headers: {
          'Authorization': `Bearer ${user.token}` 
        }
      }).then((response) => {
        localStorage.setItem('ultimaConexion', this.getCurrentDate());
        /*if(response.data.data.recintos.length > 0){
          let recintos = response.data.data.recintos;
          recintos.forEach(recinto => {
              this.deleteRecinto(recinto.Recinto);
              this.saveRecinto(recinto.Recinto);
          });
        }

        if(response.data.data.lugares.length > 0){
          let lugares = response.data.data.lugares;
          lugares.forEach(lugar => {
              this.deleteLugar(lugar.Lugar);
              this.saveLugar(lugar.Lugar);
          });
        }*/
        
        if(response.data.data.items.length > 0){
          let items = response.data.data.items;
          items.forEach(item => {
              this.deleteItem(item.Item);
              this.saveItem(item.Item);
          });
        }

        if(response.data.data.problemas.length > 0){
          let problemas = response.data.data.problemas;
          problemas.forEach(problema => {
              this.deleteProblema(problema.Problema);
              this.saveProblema(problema.Problema);
          });
        }
        this.getBaseConocimiento();
      }).catch(error => {
        console.log(error);
        this.getBaseConocimiento();
      });
    },
    filtroItemsProblemas(combinatoria){
      this.itemsFiltrados = [];
      this.problemasFiltrados = [];
      this.combinatoria = [];
      this.itemsFiltrados = this.items.filter(item => combinatoria.some(comb => comb.ItemProblema.r_item_id === item.r_item_id));
      this.problemasFiltrados = this.problemas.filter(problema => combinatoria.some(comb => comb.ItemProblema.r_problema_id === problema.r_problema_id));
      this.combinatorias = combinatoria
    },
    cambiaSelectorBaseConocimiento(tipo, valor){
      switch(tipo){
        case 'item':
          this.problemasFiltrados = this.problemas;
          var combi = this.combinatorias.filter(function(comb){ return comb.ItemProblema.r_item_id == valor});
          this.problemasFiltrados = this.problemas.filter(problema => combi.some(comb => comb.ItemProblema.r_problema_id === problema.r_problema_id));
          break;
        case 'problema':
          this.itemsFiltrados = this.items;
          var combi = this.combinatorias.filter(function(comb){ return comb.ItemProblema.r_problema_id == valor});
          this.itemsFiltrados = this.items.filter(item => combi.some(comb => comb.ItemProblema.r_item_id === item.r_item_id));
          break;
      }
    },
    getCurrentDate(){
      var today = new Date();
      var hour = today.getHours() < 10 ? "0"+today.getHours() : today.getHours();
      var min = today.getMinutes() < 10 ? "0"+today.getMinutes() : today.getMinutes();
      var month = '';
      var todayComplete = today.getDate()+"/"+(today.getMonth()+1)+"/"+today.getFullYear();
      var lastConexion =  todayComplete+" a las "+hour+":"+min;
      return lastConexion;
    }
  },
})